@import "variables"; //variables.scss
@import "fonts"; //fonts.scss

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.sr-focusable {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    box-sizing: border-box;
    clip: auto !important;
    clip-path: none;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    background-color: $brand-white;
  }
  &:hover {
    outline: 2px solid $primary-color;
  }
}

body {
  margin: 0;
  position: relative;
}

main {
  background-image: url('/images/site-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 0 bottom 0;
}

button:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: $primary-color;
}

ul {
  list-style-type: none;
  margin: 0;
}

li,
ul {
  padding: 0;
}

// .flexibleImage {
//   width: 100%;
//   height: auto !important;
// }

// .main-wrapper {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 100%;
//   max-width: 1325px;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -webkit-box-flex: 1;
//   -ms-flex: 1 1;
//   flex: 1 1;
//   overflow: hidden;
//   margin: 60px auto;
//   @media screen and (max-width: 1280px) {
//     max-width: 95%;
//   }
// }

.chat_channel_header_btn {
  height: 20px;
  width: 20px;
  margin-left:5px !important;
  margin-right:5px !important;
}

.chat_channel_header_btn img{
  height: 20px !important;
  width: 20px !important;
}

.modal-select-chat-user {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  width: 100vw;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(11, 164, 224, 0.4);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  // background-image: -o-linear-gradient(left, $primary-color, $secondary-color);
  // background-image: -webkit-gradient(
  //   linear,
  //   left top,
  //   right top,
  //   from($primary-color),
  //   to($secondary-color)
  // );
  // background-image: linear-gradient(to right, $primary-color, $secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-height: 860px) {
    //padding-top: 5%;
  }
  @media screen and (max-height: 567px) {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 6%;
  }
}

.modal-select-chat-user-inner-wrapper {
  background-color: $brand-white;
  -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 670px;
  width: 495px;
  padding: 0px 30px 30px 30px;
  h2 {
    font-size: 26px;
    font-weight: 900;
    font-family: $title-text-font;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: $brand-gray;
    @media screen and (max-width: 320px) {
      font-size: 18px;
    }
  }
  section:nth-of-type(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 20px 0;
    color: $brand-gray;
    font-size: 16px;
    @media screen and (max-width: 740px) {
      margin: 20px 0;
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      margin: 5px;
    }
  }
  @media screen and (max-width: 870px) {
    height: 650px;
    width: 440px;
  }
  @media screen and (max-width: 740px) {
    height: 510px;
    width: 350px;
    padding: 20px;
  }
  @media screen and (max-width: 740px) {
    font-size: 24px;
    margin: 5px;
  }
  @media screen and (max-width: 414px) {
    height: 460px;
    width: 320px;
  }
  @media screen and (max-width: 320px) {
    height: 420px;
    width: 305px;
  }
}

.chat-search-wrapper {
  display: block;
  max-width: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: $primary-color;
  border-radius: 8px;
  max-height: 55px;
  padding: 2px;
  margin-bottom: 30px;

  .chat-search-input {
    border: none;
    outline: none;
    height: 100%;
    width:100%;
  }

  .chat-search-button {
    position: relative;
    top: 15px;
    display: flex;
    width: 150px;
    margin-left: auto;
    margin-bottom: 0;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    border-radius: 2px;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    font-family: $title-text-font, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    text-shadow: none;
    color: $brand-white;
    cursor: pointer;
    text-decoration: none;
    transition: border 0.2s, color 0.2s, background-color 0.2s;
    // margin-top: 20px;
    white-space: nowrap;
  }
}

.chat-search-result-wrapper {
  display: block;
  max-width: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: $primary-color;
  height: 60%;
  padding: 2px;
  overflow: scroll;
  margin-bottom: 30px;

  .chat-user-select {
    width: 100%;
    height: 40px;
    padding-left: 40px;
    text-align: left;
    border: none;
  }

  .selected {
    background-color: $primary-color;
    color: $brand-white;
  }
}

.chat-search-button-wrapper {
  float: right;

  .chat-modal-button {
    float: right;
    position: relative;
    display: flex;
    width: 150px;
    margin-left: 20px;
    margin-bottom: 0;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    border-radius: 2px;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    font-family: $title-text-font, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    text-shadow: none;
    color: $brand-white;
    cursor: pointer;
    text-decoration: none;
    transition: border 0.2s, color 0.2s, background-color 0.2s;
    // margin-top: 20px;
    white-space: nowrap;
  }

  .cancel-modal-button {
    background-color: $brand-white-4;
    color: $brand-black;
    border: 1px solid $brand-white-4;
  }
}



.modal-create-chat-channel {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  width: 100vw;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(11, 164, 224, 0.4);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  // background-image: -o-linear-gradient(left, $primary-color, $secondary-color);
  // background-image: -webkit-gradient(
  //   linear,
  //   left top,
  //   right top,
  //   from($primary-color),
  //   to($secondary-color)
  // );
  // background-image: linear-gradient(to right, $primary-color, $secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-height: 860px) {
    //padding-top: 5%;
  }
  @media screen and (max-height: 567px) {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 6%;
  }
}

.modal-create-chat-channel-inner-wrapper {
  background-color: $brand-white;
  -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  // justify-content: center;
  height: 350px;
  width: 495px;
  padding: 30px 30px 30px 30px;
  h2 {
    font-size: 26px;
    font-weight: 900;
    font-family: $title-text-font;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: $brand-gray;
    @media screen and (max-width: 320px) {
      font-size: 18px;
    }
  }
  section:nth-of-type(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 20px 0;
    color: $brand-gray;
    font-size: 16px;
    @media screen and (max-width: 740px) {
      margin: 20px 0;
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      margin: 5px;
    }
  }
  @media screen and (max-width: 870px) {
    height: 650px;
    width: 440px;
  }
  @media screen and (max-width: 740px) {
    height: 510px;
    width: 350px;
    padding: 20px;
  }
  @media screen and (max-width: 740px) {
    font-size: 24px;
    margin: 5px;
  }
  @media screen and (max-width: 414px) {
    height: 460px;
    width: 320px;
  }
  @media screen and (max-width: 320px) {
    height: 420px;
    width: 305px;
  }
}

.modal-create-chat-channel-warning {
  color: $brand-red !important;
}

.chat-channel-wrapper {
  display: block;
  max-width: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: $primary-color;
  border-radius: 8px;
  max-height: 55px;
  padding: 2px;
  margin-bottom: 30px;

  .chat-channel-input {
    border: none;
    outline: none;
    height: 100%;
    width:100%;
  }

  .chat-channel-button {
    position: relative;
    top: 15px;
    display: flex;
    width: 150px;
    float: right;
    margin-left: 20px;
    margin-bottom: 0;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    border-radius: 2px;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    font-family: $title-text-font, sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    text-shadow: none;
    color: $brand-white;
    cursor: pointer;
    text-decoration: none;
    transition: border 0.2s, color 0.2s, background-color 0.2s;
    margin-top: 20px;
    white-space: nowrap;
  }

  .chat-channel-cancel-button {
    color: $brand-black !important;
    background-color: $brand-white-4 !important;
    border: 1px solid $brand-white-4;
  }
}

.chat-filter-input {
  margin-left:35px;
}

.chat-filter-cancel-button {
  border:none;
}

.chat-filter-input {
  border:none;
  outline:none;
  height: 20px;
}

.str-chat__channel-subheader {
  position: relative;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  padding: 10px 40px 10px 10px;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  outline: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.str-chat__channel-subheader-image {
  height: 20px !important;
  width: 20px !important;
  float: left;
}

.str-chat__channel-subheader-image-rotate {
  transform: rotate(270deg);
}

// .home-login-modal-wrapper {
//   z-index: 100;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   height: 100vh;
//   width: 100vw;
//   -webkit-transform: translate(-50%, -50%);
//   -ms-transform: translate(-50%, -50%);
//   transform: translate(-50%, -50%);
//   background-image: -o-linear-gradient(left, $primary-color, $secondary-color);
//   background-image: -webkit-gradient(
//     linear,
//     left top,
//     right top,
//     from($primary-color),
//     to($secondary-color)
//   );
//   background-image: linear-gradient(to right, $primary-color, $secondary-color);
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   @media screen and (max-height: 860px) {
//     padding-top: 5%;
//   }
//   @media screen and (max-height: 567px) {
//     position: absolute;
//     min-height: 100%;
//     min-width: 100%;
//     top: 6%;
//   }
// }

// .home-login-inner-wrapper {
//   background-color: $brand-white;
//   -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
//   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   height: 670px;
//   width: 495px;
//   h2 {
//     font-size: 26px;
//     font-weight: 900;
//     font-family: $title-text-font;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.17;
//     letter-spacing: normal;
//     text-align: center;
//     color: $brand-gray;
//     @media screen and (max-width: 320px) {
//       font-size: 18px;
//     }
//   }
//   section:nth-of-type(2) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     margin: 20px 0;
//     color: $brand-gray;
//     font-size: 16px;
//     @media screen and (max-width: 740px) {
//       margin: 20px 0;
//       font-size: 16px;
//     }
//     @media screen and (max-width: 320px) {
//       margin: 5px;
//     }
//   }
//   @media screen and (max-width: 870px) {
//     height: 650px;
//     width: 440px;
//   }
//   @media screen and (max-width: 740px) {
//     height: 510px;
//     width: 350px;
//     padding: 2px;
//   }
//   @media screen and (max-width: 740px) {
//     font-size: 24px;
//     margin: 5px;
//   }
//   @media screen and (max-width: 414px) {
//     height: 460px;
//     width: 320px;
//   }
//   @media screen and (max-width: 320px) {
//     height: 420px;
//     width: 305px;
//   }
// }

// .login-logo {
//   height: 61px;
//   width: 340px;
//   margin: 45px 5px 25px 5px;
//   @media screen and (max-width: 740px) {
//     height: 40px;
//     width: 200px;
//     margin: 15px 5px;
//   }
//   @media screen and (max-width: 320px) {
//     height: 30px;
//     width: 120px;
//   }
// }

// .home-login-label {
//   font-family: $title-text-font;
//   color: $secondary-color;
//   font-size: 13px;
//   font-weight: 700;
//   text-align: left;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   display: block;
//   margin-bottom: 5px;
//   @media screen and (max-width: 740px) {
//     font-size: 13px;
//     text-align: left;
//     margin-bottom: 5px;
//   }
// }

// .home-login-input {
//   display: block;
//   width: 100%;
//   height: 38px;
//   padding: 8px 0 8px 5px;
//   margin-bottom: 10px;
//   margin-right: 5px;
//   font-size: 18px;
//   line-height: 1.428571429;
//   color: $brand-gray;
//   background-color: $brand-white;
//   border: 1px solid $brand-off-white;
//   @media screen and (max-width: 740px) {
//     width: 100%;
//     height: 23px;
//     padding: 8px 0 8px 5px;
//     margin-bottom: 10px;
//     margin-right: 5px;
//     font-size: 18px;
//   }
//   @media screen and (max-width: 320px) {
//     width: 97%;
//   }
// }

// .modal-login-checkbox {
//   margin-right: 5px;
//   height: 16px;
//   @media screen and (max-width: 740px) {
//     margin-right: 5px;
//     height: 16px;
//   }
//   @media screen and (max-width: 414px) {
//     height: 11.5px;
//   }
// }

// .forgot-password-link {
//   text-decoration: underline;
//   color: $brand-gray;
//   @media screen and (max-width: 320px) {
//     font-size: 12px;
//   }
// }

// .modal-login-button {
//   width: 100% !important;
//   border: solid 1px $secondary-color !important;
//   background-color: $secondary-color !important;
//   margin-bottom: 10px !important;
//   @media screen and (max-width: 740px) {
//     width: 100% !important;
//     margin-bottom: 10px !important;
//   }
//   @media screen and (max-width: 414px) {
//     padding: 8px;
//   }
// }

// .modal-register-button {
//   margin-bottom: 10px !important;
//   width: 100% !important;
//   @media screen and (max-width: 740px) {
//     margin-bottom: 10px !important;
//     width: 100% !important;
//   }
//   @media screen and (max-width: 414px) {
//     padding: 8px;
//   }
// }

// .modal-bottom-text {
//   width: 400px;
//   font-size: 12px;
//   text-align: left;
//   color: $brand-light-grey;
//   margin-top: 25px;
//   @media screen and (max-width: 740px) {
//     width: 300px;
//     font-size: 12px;
//     margin-top: 5px;
//   }
//   @media screen and (max-width: 414px) {
//     font-size: 10px;
//   }
// }

// .homepage-midtop {
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: top;
//   -ms-flex-align: top;
//   align-items: top;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   @media screen and (max-width: 1280px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: row;
//     flex-direction: row;
//   }
//   @media screen and (max-width: 640px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//   }
// }

// .banner-navigation {
//   position: absolute;
//   bottom: 10px;
//   left: 0;
//   right: 0;
//   margin-left: auto;
//   margin-right: auto;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   width: 300px;

//   .banner-navigation__button {
//     border: none;
//     background-color: transparent;
//     cursor: pointer;
//   }
//   svg {
//     height: 15px;
//     width: 15px;
//   }
// }

// .banner-navigation__button-active {
//   svg {
//     path {
//       fill: gray;
//     }
//   }
// }

// .vertical-sponsor-banner {
//   margin-top: 3px;
//   img {
//     max-width: 300px;
//     height: auto !important;
//     margin: auto !important;
//   }
//   @media screen and (max-width: 1280px) {
//     margin-bottom: 15px;
//     text-align: center;
//     width: 40%;
//   }
//   @media screen and (max-width: 990px) {
//     margin-top: 130px;
//     text-align: center;
//     width: 50%;
//   }
//   @media screen and (max-width: 640px) {
//     margin: 40px auto 0;
//     text-align: center;
//     width: 70%;
//   }
//   @media screen and (max-width: 480px) {
//     width: 100%;
//   }
// }

// .horizontal-sponsor-banner-container {
//   margin: 50px 0;
//   img {
//     width: 100%;
//     width: 100%;
//   }
//   @media screen and (max-width: 640px) {
//     margin: 30px 0;
//   }
// }

// .np-wrapper {
//   width: 75%;
//   @media screen and (max-width: 990px) {
//     width: 95%;
//     margin: 0 auto;
//   }
//   @media screen and (max-width: 940px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//   }
//   @media screen and (max-width: 640px) {
//     margin-bottom: 50px;
//   }
// }

// .np-content-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   height: 100%;
//   padding-right: 5px;
//   width: 100%;
// }

// .np-title {
//   font-family: $title-text-font;
//   color: $brand-gray;
//   font-size: 45px;
//   text-align: left;
//   font-weight: 900px;
//   margin-left: 10px;
//   @media screen and (max-width: 990px) {
//     margin-bottom: 10px;
//   }
//   @media screen and (max-width: 705px) {
//     text-align: center;
//     margin-left: 0;
//     font-size: 34px;
//   }
// }

// .large-card,
// .np-cards {
//   width: 90%;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
// }

// .np-cards {
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   @media screen and (max-width: 990px) {
//     width: 100%;
//   }
//   @media screen and (max-width: 705px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//   }
// }

// .large-card {
//   background: $brand-off-white;
//   height: 250px;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   margin: 15px 5px;
//   padding: 30px 30px 30px 40px;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   cursor: pointer;
//   h1 {
//     font-family: $title-text-font;
//     color: $primary-color;
//     font-weight: 900;
//     font-size: 24px;
//     margin: 0;
//     @media screen and (max-width: 480px) {
//       font-size: 20px;
//     }
//     @media screen and (max-width: 414px) {
//       font-size: 20px;
//     }
//   }
//   h2 {
//     color: $brand-gray;
//     font-weight: 500;
//     font-family: $title-text-font;
//     font-size: 15px;
//     margin: 0;
//     line-height: 20px;
//     @media screen and (max-width: 480px) {
//       margin: 10px 0;
//     }
//   }
//   h3 {
//     font-family: $title-text-font;
//     font-size: 15px;
//     margin: 0;
//     line-height: 20px;
//     color: $primary-color;
//     font-weight: 900;
//     @media screen and (max-width: 480px) {
//       margin: 10px 0;
//     }
//   }
//   .details-button {
//     width: 90px;
//     padding: 10px 20px;
//   }
//   @media (max-width: 1200px) {
//     width: 600px;
//     height: 250px;
//   }
//   @media (max-width: 1080px) {
//     width: 90%;
//     height: 250px;
//   }
//   @media screen and (max-width: 940px) {
//     height: 250px;
//   }
//   @media screen and (max-width: 705px) {
//     height: 200px;
//     padding: 20px;
//   }
//   @media screen and (max-width: 480px) {
//     width: 300px;
//     height: 250px;
//   }
//   @media screen and (max-width: 414px) {
//     width: 230px;
//     height: 250px;
//   }
// }

// .main-wrapper-sessions {
//   padding: 160px 0 120px;
// }

// .register-page {
//   background-image: -o-linear-gradient(
//       left,
//       $primary-color,
//       rgba(16, 176, 230, 0)
//     ),
//     url(/images/register-bkgnd-img-1.png);
//   background-image: -webkit-gradient(
//       linear,
//       left top,
//       right top,
//       from($primary-color),
//       to(rgba(16, 176, 230, 0))
//     ),
//     url(/images/register-bkgnd-img-1.png);
//   background-image: linear-gradient(
//       90deg,
//       $primary-color,
//       rgba(16, 176, 230, 0)
//     ),
//     url(/images/register-bkgnd-img-1.png);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: top;
//   padding: 80px 0 30px;
// }

// .register-page-content {
//   max-width: 1140px;
//   width: 100%;
//   margin: auto;
//   registerpage-form {
//     p {
//       opacity: 0.8;
//       font-size: 16px;
//       font-weight: 400;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.13;
//       letter-spacing: normal;
//       padding-right: 25px;
//       text-align: left;
//       font-family: $title-text-font;
//       color: $brand-white;
//     }
//   }
//   @media screen and (max-width: 1280px) {
//     width: 90%;
//   }
//   @media screen and (max-width: 990px) {
//     width: 95%;
//   }
//   @media screen and (max-width: 740px) {
//     margin: auto;
//     width: 95%;
//   }
// }

// .registerpage-title {
//   margin: 100px 35px 10px 0;
//   font-size: 45px;
//   font-weight: 900px;
//   text-align: left;
//   font-family: $title-text-font;
//   color: $brand-white;
//   @media (max-width: 1100px) {
//     margin: 100px 0 10px;
//     font-size: 40px;
//   }
//   @media screen and (max-width: 740px) {
//     margin: 70px 0 10px;
//   }
//   @media screen and (max-width: 705px) {
//     font-size: 32px;
//   }
// }

// .registerpage-form {
//   width: 60%;
//   margin-bottom: 60px;
//   max-width: 1000px;
//   text-align: left;
//   .error-message-container {
//     width: 50%;
//     text-align: left;
//   }
//   .checkbox-container {
//     &label,
//     &a {
//       @media (max-width: 1100px) {
//         font-size: 14px;
//       }
//     }
//   }
//   .error-message-title {
//     color: $brand-white;
//     font-size: 30px !important;
//     padding-top: 50px;
//   }
//   .error-message {
//     text-align: left;
//     color: $brand-white;
//     font-size: 20px;
//   }
//   .input-form {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     width: 70%;
//     @media screen and (max-width: 740px) {
//       width: 100%;
//       input {
//         height: 52px;
//         margin-bottom: 15px;
//         padding-right: 5px;
//         padding-left: 5px;
//       }
//     }
//   }
//   .checkbox-wrapper h1 {
//     color: $secondary-color;
//     font-size: 16px;
//   }
//   a {
//     color: $secondary-color;
//   }
//   form {
//     div {
//       width: 100%;
//       &:first-of-type {
//         margin-right: 15px;
//       }
//       &:last-of-type {
//         margin-right: 0;
//       }
//     }
//     section {
//       width: 100%;
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-orient: horizontal;
//       -webkit-box-direction: normal;
//       -ms-flex-direction: row;
//       flex-direction: row;
//       -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     }
//   }
//   input,
//   select {
//     height: 24px;
//     margin-bottom: 15px;
//     padding-right: 5px;
//     padding-left: 5px;
//     border-radius: 5px;
//     font-family: $title-text-font;
//     color: $brand-gray;
//     font-size: 16px;
//     line-height: 24px;
//     width: -webkit-fill-available;
//   }
//   .hidden-label {
//     color: $secondary-color;
//     font-size: 16px;
//   }
//   select {
//     height: 42px;
//     @media screen and (max-width: 740px) {
//       height: 52px;
//       margin-bottom: 15px;
//       padding-right: 5px;
//       padding-left: 5px;
//     }
//   }
//   button {
//     text-align: left;
//     background-color: $secondary-color;
//     margin: 0;
//   }
//   @media screen and (max-width: 740px) {
//     width: 100%;
//   }
// }

// .registerpage-form-label {
//   font-family: $title-text-font;
//   color: $secondary-color;
//   font-size: 13px;
//   font-weight: 700;
//   text-align: left;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   display: block;
//   margin-bottom: 5px;
// }

// .register-page {
//   .bottom-text {
//     font-size: 12px;
//     @media screen and (max-width: 740px) {
//       font-size: 10px;
//     }
//   }
// }

// .speaker-session-info {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   padding: 20px 30px;
//   max-width: 690px;
//   padding-top: 0;

//   article {
//     margin-top: 10px !important;
//     margin-bottom: 60px;
//   }

//   .videoWrapper {
//     position: relative;
//     width: 100%;
//     overflow: hidden;
//     padding-top: 56.25%;
//     #subsession-video {
//       position: absolute;
//       top: 0;
//       left: 0;
//       bottom: 0;
//       right: 0;
//       width: 100%;
//       height: 100%;
//       border: none;
//     }
//   }

//   @media screen and (max-width: 940px) {
//     max-width: 490px;
//   }
//   @media screen and (max-width: 740px) {
//     padding: 0;
//   }
// }

// .single-session-left {
//   background-color: $brand-white-2;
//   padding: 15px;

//   .supplemental-files-label {
//     margin-top: 15px;
//   }
//   @media screen and (max-width: 740px) {
//     background-color: transparent;
//     padding: 5px;
//   }
// }

// .session-tab {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   border-top: 1px solid rgba(18, 7, 107, 0.5);
//   padding-top: 10px;
//   margin-bottom: 30px;
//   width: 100%;

//   @media screen and (max-width: 740px) {
//     flex-wrap: wrap;
//   }

//   img {
//     height: 20px;
//     display: block;
//     @media screen and (max-width: 1180px) {
//       margin-right: -10px;
//     }
//   }

//   .session-title {
//     padding: 0 40px 0 0;
//     color: $primary-color;
//     font-size: 20px;
//     font-family: $title-text-font;
//     margin: 0 0 30px;
//     line-height: 30px;
//     font-weight: 700;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: top;
//     -ms-flex-align: top;
//     align-items: top;
//   }

//   .session-meta {
//     &:nth-of-type(2) {
//       font-family: $title-text-font;
//       font-size: 14px;
//       font-weight: 700;
//       color: $brand-gray;
//       margin-top: 5px;
//       margin-right: 13px;
//     }

//     &:nth-of-type(3),
//     &:nth-of-type(4),
//     &:nth-of-type(5) {
//       color: $primary-color;
//       text-transform: none;
//       font-family: $title-text-font;
//       font-size: 14px;
//       font-weight: 700;
//       margin: 17px 13px 17px 0;
//     }
//   }

//   .session-meta:first-of-type {
//     font-family: $title-text-font;
//     line-height: 20px;
//     font-weight: 700;
//     font-size: 14px;
//     display: block;
//     width: auto;
//     margin: 0 13px 0 0;
//     color: $brand-gray;
//     white-space: nowrap;
//     // text-transform: uppercase;
//   }

//   .session-speakers-list {
//     display: block;
//     margin: 0 40px 0 0;
//     font-size: 14px;
//     line-height: 19px;
//     color: $primary-color;
//     font-weight: 700;
//     font-family: $title-text-font;
//   }

//   .speaker-list {
//     flex-direction: row;
//     -ms-flex-direction: row;
//   }

//   .cropped-article .speaker-list {
//     display: none;
//   }

//   div {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     margin-top: 20px;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;

//     @media screen and (max-width: 705px) {
//       -webkit-box-orient: vertical;
//       -webkit-box-direction: normal;
//       -ms-flex-direction: column;
//       flex-direction: column;
//       -webkit-box-align: start;
//       -ms-flex-align: start;
//       align-items: flex-start;
//     }
//   }

//   article,
//   p {
//     margin: 0;
//     padding: 0 40px 0 0;
//     color: $brand-gray;
//     font-size: 16px;
//     font-weight: 400;
//     font-family: $title-text-font;
//     line-height: 24px;
//   }

//   section {
//     // flex: 1;
//     &:nth-of-type(2) {
//       width: 100%;
//       h1 {
//         @media screen and (max-width: 740px) {
//           display: none;
//         }
//       }
//       article {
//         @media screen and (max-width: 740px) {
//           margin-top: 30px;
//           margin-bottom: 30px;
//         }
//       }
//     }

//     &:first-of-type {
//       width: 300px;
//       @media screen and (max-width: 740px) {
//         width: 100%;
//         margin-top: 5px;
//       }
//       @media screen and (max-width: 414px) {
//         width: auto;
//       }
//       h1 {
//         display: none;
//         @media screen and (max-width: 740px) {
//           display: block;
//         }
//         @media screen and (max-width: 480px) {
//           font-size: 20px;
//         }
//       }
//     }
//   }
// }

// .img-button {
//   border: none;
//   //outline: none;
//   background: transparent;
//   padding: 5px;

//   &:focus {
//     background-color: $brand-white-3;
//   }

//   img {
//     height: 20px;
//     display: block;
//     //@media screen and (max-width: 1180px) {
//     //  margin-right: -10px;
//     //}
//   }
// }

// .live-stream-session-description {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   span {
//     h3 {
//       font-family: $title-text-font;
//       line-height: 22px;
//       font-weight: 700;
//       font-size: 16px;
//       display: block;
//       width: auto;
//       text-transform: none;
//       color: $brand-gray;
//       white-space: nowrap;
//       border-bottom: 1px solid $primary-color;
//       max-width: 300px;
//     }
//     h3:nth-of-type(2) {
//       margin-top: 15px;
//     }
//     @media screen and (max-width: 480px) {
//       padding: 0 12px;
//     }
//   }

//   h2 {
//     margin-bottom: 2px;
//     color: $primary-color;
//     font-family: $title-text-font;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 20px;
//   }
//   @media screen and (max-width: 480px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//   }
// }

// .session-title-link {
//   color: $primary-color;
//   -webkit-transition: color 0.2s;
//   -o-transition: color 0.2s;
//   transition: color 0.2s;
//   max-width: 90%;
//   &:hover {
//     color: $primary-color;
//   }
// }

// .session-title-mobile {
//   display: none !important;
//   .expand-img-mobile {
//     @media screen and (max-width: 740px) {
//       margin: 0 0 0 15px;
//       cursor: pointer;
//     }
//   }
//   @media screen and (max-width: 740px) {
//     display: -webkit-box !important;
//     display: -ms-flexbox !important;
//     display: flex !important;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -webkit-box-pack: end;
//     -ms-flex-pack: end;
//     justify-content: flex-end;
//   }
// }

// .live-stream-session-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   -webkit-box-align: start;
//   -ms-flex-align: start;
//   align-items: flex-start;
//   width: 100%;
//   margin-top: -50px;
//   article {
//     margin: 0;
//     padding: 0 40px 0 0;
//     color: $brand-light-grey;
//     font-size: 16px;
//     font-weight: 400;
//     font-family: $title-text-font;
//     line-height: 24px;
//   }
//   h3 {
//     margin: 0;
//     padding: 0 40px 0 0;
//     color: $brand-black;
//     font-size: 16px;
//     font-weight: 700;
//     font-family: $title-text-font;
//     line-height: 24px;
//     margin-bottom: 10px;
//   }
//   section:first-of-type {
//     max-width: 900px;
//   }
//   h1 {
//     padding: 0 40px 0 0;
//     color: $primary-color;
//     font-size: 20px;
//     font-family: $title-text-font;
//     margin: 0 0 30px;
//     line-height: 29px;
//     font-weight: 700;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: top;
//     -ms-flex-align: top;
//     align-items: top;
//   }
//   @media screen and (max-width: 940px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//   }
// }

// .expand-img {
//   cursor: pointer;
// }

// .cropped-article {
//   height: 74px;
//   overflow: hidden;
//   -webkit-transition: all 2s ease-in-out;
//   -o-transition: all 2s ease-in-out;
//   transition: all 2s ease-in-out;
// }

// .expanded-article {
//   height: auto;
// }

// .single-session {
//   border: none;
//   h1 {
//     font-size: 30px;
//   }
// }

// .single-container-left {
//   background: $brand-off-white;
//   margin-bottom: 20px;
//   padding: 10px 15px;
//   margin-top: 20px;
//   @media screen and (max-width: 940px) {
//     max-width: 650px;
//     margin-top: 0;
//     margin-bottom: 0;
//   }
//   @media screen and (max-width: 740px) {
//     width: 80%;
//   }
//   @media screen and (max-width: 705px) {
//     width: 80%;
//   }
//   @media screen and (max-width: 480px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//   }
// }

// .main-wrapper.single-speaker .single-container-left .logo-website-link {
//   cursor: pointer;
//   text-decoration: none;
// }

// .no-logo-website-link {
//   cursor: default;
//   text-decoration: none;
//   pointer-events: none;
// }

// .iframe-container {
//   position: relative;
//   width: 100%;
//   padding-top: 56.25%;
//   margin: 20px 0 50px 0;

//   .exhibitor-video {
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     border: none;
//   }
// }

// .single-exhibitor-mobile {
//   display: none;
//   @media screen and (max-width: 940px) {
//     display: block;
//     text-align: left;
//     padding: 20px 0;
//     font-size: 30px !important;
//   }
// }

// .single-page-top-img {
//   display: block;
//   margin: "0 auto 40px";
//   width: 100%;
// }

// .company-image-large {
//   height: 300px !important;
//   width: 300px !important;
//   background-position: center;
//   background-color: $brand-white;
// }

// .content-wrapper-with-contact {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   div {
//     .representative-wrapper {
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-orient: vertical;
//       -webkit-box-direction: normal;
//       -ms-flex-direction: column;
//       flex-direction: column;
//       -webkit-box-align: end;
//       -ms-flex-align: end;
//       align-items: flex-end;
//     }
//     @media screen and (max-width: 705px) {
//       max-width: 100%;
//     }
//     @media screen and (max-width: 640px) {
//       padding: 20px 5px 10px 5px;
//     }
//   }
// }

// .single-list-details {
//   .details-title {
//     text-align: right;
//   }
//   a {
//     font-weight: 700;
//     font-size: 13px;
//   }
//   li {
//     color: $brand-gray;
//     line-height: 1.5em;
//     font-family: $title-text-font;
//     font-size: 14px;
//     text-align: right;
//   }
//   .details-title {
//     font-family: $title-text-font;
//     font-size: 15px;
//     font-weight: 600;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.1;
//     letter-spacing: normal;
//     text-align: right;
//     color: $primary-color;
//     text-transform: uppercase;
//     border-bottom: 1px solid $primary-color;
//     padding-bottom: 3px;
//     @media screen and (max-width: 640px) {
//       text-align: left;
//     }
//   }
//   @media screen and (max-width: 640px) {
//     margin-bottom: 0 !important;
//   }
// }

// .schedule-meeting-container {
//   margin-top: 20px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   max-width: 100% !important;
//   -webkit-box-pack: end;
//   -ms-flex-pack: end;
//   justify-content: flex-end;
//   .details-button {
//     width: auto;
//   }
// }

// .left-details {
//   .details-title,
//   li {
//     text-align: left;
//     width: 250px;
//   }
//   .details-title {
//     margin-top: 30px;
//   }
// }

// .pdf-links {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   margin-top: 10px;
//   .link-title {
//     line-height: 16px;
//     height: -webkit-max-content;
//     height: -moz-max-content;
//     height: max-content;
//     margin: 0 0 0 10px;
//     font-weight: 700;
//     &:hover {
//       color: $secondary-color;
//     }
//   }
// }

// .details-button {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 190px;
//   margin-right: 0;
//   margin-bottom: 0;
//   padding: 15px 30px;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-flex: 0;
//   -ms-flex: 0 1 auto;
//   flex: 0 1 auto;
//   border-radius: 2px;
//   background-color: $primary-color;
//   border: 1px solid $primary-color;
//   font-family: $title-text-font;
//   font-size: 15px;
//   line-height: 20px;
//   font-weight: 500;
//   text-align: center;
//   text-shadow: none;
//   color: $brand-white;
//   cursor: pointer;
//   text-decoration: none;
//   -webkit-transition: border 0.2s, color 0.2s, background-color 0.2s;
//   -o-transition: border 0.2s, color 0.2s, background-color 0.2s;
//   transition: border 0.2s, color 0.2s, background-color 0.2s;
//   margin-top: 0 !important;
//   white-space: nowrap;
//   &.business-card-button {
//     background-color: $primary-color;
//     border: 1px solid $primary-color;
//     &.inactive {
//       background-color: $brand-light-grey;
//       border: unset;
//       &:hover:not(:disabled) {
//         background-color: $brand-light-grey !important;
//         color: $brand-white !important;
//         border: unset !important;
//         cursor: default;
//       }
//     }
//   }
//   @media (max-width: 1080px) {
//     margin-right: 20px;
//   }
//   @media screen and (max-width: 870px) {
//     margin-right: 0px;
//   }
//   @media screen and (max-width: 480px) {
//     width: 80px;
//     padding: 15px;
//   }

//   &.disclosure-button {
//     margin-top: 10px !important;
//     width: auto;
//     padding: 15px;
//     img {
//       margin-right: 5px;
//     }
//     .default-icon {
//       display: block;
//     }
//     .hover-icon {
//       display: none;
//     }
//     &:hover,
//     &:focus {
//       .default-icon {
//         display: none;
//       }
//       .hover-icon {
//         display: block;
//       }
//     }
//   }

//   &.details-button-inverse {
//     color: $primary-color;
//     background-color: $brand-white;
//     &:hover,
//     &:focus {
//       color: $brand-white !important;
//       background-color: $primary-color !important;
//     }
//   }

//   &.details-button-primary {
//     background-color: $primary-color;
//     border-color: $primary-color;
//     color: $brand-white !important;

//     &:hover,
//     &:focus {
//       background-color: $brand-white !important;
//       border-color: $primary-color !important;
//       color: $primary-color !important;
//     }
//   }

//   &.details-button-secondary {
//     background-color: $secondary-color;
//     border-color: $secondary-color;
//     color: $brand-white !important;

//     &:hover,
//     &:focus {
//       background-color: $brand-white !important;
//       border-color: $secondary-color !important;
//       color: $secondary-color !important;
//     }
//   }
// }

// .live-stream-button {
//   max-width: 100px;
//   width: auto;
// }

// .add-to-calendar-button {
//   width: fit-content;
//   max-height: 25px;
//   font-family: $title-text-font !important;
//   border: 1px solid $primary-color !important;
//   margin-bottom: 2em;
//   color: $primary-color !important;
//   outline: none !important;

//   img {
//     padding-right: 10px;
//   }

//   @media screen and (max-width: 1180px) {
//     img {
//       padding-right: 20px;
//     }
//   }
// }

// .download-overview-container {
//   padding-top: 40px;
// }

// .download-agenda-button {
//   max-width: 300px;
//   width: auto;
// }

// .filter-button {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: auto;
//   padding: 10px 20px;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-flex: 0;
//   -ms-flex: 0 1 auto;
//   flex: 0 1 auto;
//   border-radius: 2px;
//   border: 1px solid $brand-off-white;
//   font-family: $title-text-font;
//   font-size: 13px;
//   line-height: 20px;
//   font-weight: 500;
//   text-align: center;
//   text-shadow: none;
//   color: $brand-light-grey;
//   cursor: pointer;
//   text-decoration: none;
//   margin: 5px;
//   -webkit-transition: background-color, border, color, 0.2s;
//   -o-transition: background-color, border, color, 0.2s;
//   transition: background-color, border, color, 0.2s;
//   &:hover {
//     border: 1px solid $primary-color;
//   }
//   @media screen and (max-width: 740px) {
//     margin: 3px;
//     padding: 6px 10px;
//   }
//   @media screen and (max-width: 640px) {
//     margin: 8px auto !important;
//   }
// }

// .details-button-large {
//   width: 240px;
//   padding: 15px;
//   font-size: 18px;
//   &:hover {
//     background-color: $secondary-color !important;
//     border: 1px solid $secondary-color !important;
//     color: $brand-white !important;
//   }
//   @media screen and (max-width: 940px) {
//     width: 240px;
//     padding: 15px;
//     font-size: 18px;
//   }
// }

// .filters-wrapper-large {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   justify-content: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   margin: 30px;
// }

// .filter-button-active {
//   color: $brand-white;
//   border: 1px solid $primary-color;
//   background-color: $primary-color;
// }

// .filter-button-active:hover {
//   color: $brand-white !important;
//   border: 1px solid $primary-color !important;
//   background-color: $primary-color !important;
// }

// .company-single-page h1 {
//   font-family: $title-text-font;
//   font-weight: 700;
//   margin-bottom: 0;
//   text-transform: none;
//   color: $brand-gray;
//   font-size: 54px;
//   margin-top: 90px;
//   @media screen and (max-width: 740px) {
//     margin-top: 40px;
//     font-size: 40px;
//   }
// }

// .filters-wrapper {
//   div {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     -webkit-box-pack: start;
//     -ms-flex-pack: start;
//     justify-content: flex-start;
//     margin-left: -5px;
//   }
//   h2 {
//     font-family: $title-text-font;
//     font-size: 23px;
//     font-weight: 500;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.17;
//     letter-spacing: normal;
//     text-align: left;
//     color: $primary-color;
//     margin-top: 80px;
//     @media screen and (max-width: 740px) {
//       margin-top: 40px;
//       margin-bottom: 6px;
//     }
//   }
//   @media screen and (max-width: 1280px) {
//     padding: 0 15px;
//   }
// }

// .filters-wrapper-sessions {
//   margin-bottom: 100px;
// }

// .details-button:hover,
// .login-submit-button:hover,
// .login-form-register:hover,
// .login-form-forgot:hover {
//   &:not(:disabled) {
//     background-color: $brand-white !important;
//     border: 1px solid $primary-color !important;
//     color: $primary-color !important;
//   }
// }

// .details-button:focus,
// .login-submit-button:focus,
// .login-form-register:focus,
// .login-form-forgot:focus {
//   background-color: $brand-white !important;
//   border: 1px solid $primary-color !important;
//   color: $primary-color !important;
// }

// .details-button.disclosure-button {
//   &:hover,
//   &:focus {
//     background-color: $primary-color !important;
//     border: 1px solid $primary-color !important;
//     color: $brand-white !important;
//   }
// }

// .details-button.live-stream-button:hover {
//   background-color: $secondary-color !important;
//   border: 1px solid $secondary-color !important;
//   color: $brand-white !important;
// }

// .details-button-inverse:hover {
//   color: $brand-white;
//   background-color: $primary-color;
//   border: 1px solid $primary-color;
// }

// .details-button-active {
//   background-color: $primary-color !important;
//   border: 1px solid $primary-color !important;
// }

// .details-button-white {
//   background-color: $brand-white;
//   color: $primary-color;
//   border: 1px solid $brand-white;
//   width: 80px;
//   &:hover {
//     color: $brand-white;
//     background-color: $primary-color;
//     border: 1px solid $primary-color;
//   }
// }

// .details-button-header {
//   width: 176px;
//   padding: 16px 0;
//   font-size: 17px;
//   background-color: $primary-color;
//   border: 1px solid $primary-color;
//   color: $brand-white !important;
//   &:hover {
//     background-color: $primary-color;
//     border: 1px solid $primary-color;
//   }
//   &.invert {
//     background-color: $brand-white;
//     border: 1px solid $primary-color;
//     color: $primary-color !important;
//     &:hover {
//       background-color: $primary-color !important;
//       border: 1px solid $primary-color !important;
//       color: $brand-white !important;
//     }
//   }
//   @media screen and (max-width: 1280px) {
//     width: 186px;
//     padding: 21px 0;
//     font-size: 17px;
//   }
//   @media (max-width: 1200px) {
//     width: 170px;
//     padding: 20px 0;
//     font-size: 15px;
//   }
// }

// .health-safety-button {
//   width: 208px !important;
//   padding: 10px 0 !important;
//   border: 1px solid $primary-color;
//   color: $primary-color !important;
//   background-color: $brand-white;
//   &:hover {
//     color: $brand-white !important;
//     background-color: $primary-color;
//   }
//   @media screen and (max-width: 1280px) {
//     margin-bottom: 20px;
//   }
// }

// .details-button-hero {
//   color: $primary-color;
//   padding: 22px 0;
//   font-size: 17px;
//   background-color: $brand-white;
//   border: 1px solid $primary-color;
//   -webkit-transition: background-color, color, 0.2s;
//   -o-transition: background-color, color, 0.2s;
//   transition: background-color, color, 0.2s;
//   &:hover {
//     padding: 22px 0;
//     background-color: $primary-color;
//     color: $brand-white;
//     @media screen and (max-width: 740px) {
//       padding: 15px 0;
//     }
//   }
//   @media screen and (max-width: 740px) {
//     padding: 15px 0;
//   }
// }

// .day-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   @media screen and (max-width: 740px) {
//     display: block;
//   }
// }

// .day-container-livestream {
//   margin: auto auto 50px;
//   width: -webkit-max-content;
//   width: -moz-max-content;
//   width: max-content;
// }

// #filter-navigation {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 100%;
//   max-width: 1140px;
//   min-width: 100%;
//   clear: none;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   align-self: stretch;
//   grid-auto-columns: 1fr;
//   grid-column-gap: 16px;
//   grid-row-gap: 16px;
//   -ms-grid-columns: 1fr 16px 1fr;
//   grid-template-columns: 1fr 1fr;
//   -ms-grid-rows: auto 16px auto;
//   grid-template-rows: auto auto;
//   height: 163px;
//   > {
//     * {
//       &:nth-child(1) {
//         -ms-grid-row: 1;
//         -ms-grid-column: 1;
//       }

//       &:nth-child(2) {
//         -ms-grid-row: 1;
//         -ms-grid-column: 3;
//       }

//       &:nth-child(3) {
//         -ms-grid-row: 3;
//         -ms-grid-column: 1;
//       }

//       &:nth-child(4) {
//         -ms-grid-row: 3;
//         -ms-grid-column: 3;
//       }
//     }

//     :first-child {
//       -ms-grid-row: 1;
//       -ms-grid-column: 1;
//     }

//     :nth-child(2) {
//       -ms-grid-row: 1;
//       -ms-grid-column: 3;
//     }

//     :nth-child(3) {
//       -ms-grid-row: 3;
//       -ms-grid-column: 1;
//     }

//     :nth-child(4) {
//       -ms-grid-row: 3;
//       -ms-grid-column: 3;
//     }
//   }
//   section {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     @media (max-width: 1100px) {
//       margin-right: 15px;
//     }
//     @media screen and (max-width: 740px) {
//       width: 100%;
//       padding-top: 20px;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//     }
//   }
//   h1 {
//     @media screen and (max-width: 740px) {
//       font-size: 34px;
//       margin-top: 15px;
//       margin-bottom: 0;
//     }
//   }
//   @media screen and (max-width: 1280px) {
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     width: auto;
//   }
//   @media screen and (max-width: 740px) {
//     display: block;
//     height: 136px;
//   }
//   @media screen and (max-width: 740px) {
//     margin-top: 25px;
//   }
// }

// #filter-navigation-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 100%;
//   min-width: 100%;
//   margin-top: 0;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -webkit-box-flex: 1;
//   -ms-flex: 1 1;
//   flex: 1 1;
// }

// .filter-image-active {
//   display: block;
//   height: 7px;
//   margin-right: 8px;
// }

// .filter-image-inactive {
//   display: none;
// }

// .w-placeholder {
//   display: block;
//   width: 100%;
//   height: 38px;
//   padding: 8px 12px;
//   margin-bottom: 10px;
//   font-size: 14px;
//   line-height: 1.428571429;
//   color: $brand-gray;
//   vertical-align: middle;
//   background-color: $brand-white;
//   border: 1px solid $brand-off-white;
//   &:-moz-placeholder {
//     color: $brand-light-grey;
//   }
//   &::-moz-placeholder {
//     color: $brand-light-grey;
//     opacity: 1;
//   }
//   &:-ms-input-placeholder,
//   &::-webkit-input-placeholder {
//     color: $brand-light-grey;
//   }
//   &:focus {
//     outline: 0;
//   }
//   &[disabled],
//   &[readonly] {
//     cursor: not-allowed;
//     background-color: $brand-white;
//   }
// }

// .w-padding {
//   margin-right: 15px;
//   margin-left: 15px;
// }

// .paginate-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: end;
//   -ms-flex-pack: end;
//   justify-content: flex-end;
//   -webkit-box-align: end;
//   -ms-flex-align: end;
//   align-items: flex-end;
// }

// .paginate-container-large {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: end;
//   -ms-flex-pack: end;
//   justify-content: flex-end;
//   -webkit-box-align: end;
//   -ms-flex-align: end;
//   align-items: flex-end;
//   margin-top: 40px;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
// }

// .paginate-container a {
//   font-family: $title-text-font;
//   text-transform: none;
//   line-height: 22px;
//   font-weight: 700;
//   margin-right: 13px;
//   font-size: 16px;
//   display: block;
//   width: auto;
//   color: $primary-color;
//   border: none;
//   border-bottom: 1px solid $primary-color;
//   margin-bottom: 10px;
//   padding: 0 0 2px;
//   cursor: pointer;
//   &:hover {
//     border-bottom: 1px solid $primary-color;
//     color: $primary-color;
//   }
// }

// .company-list {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   margin-top: 50px;
// }

// .speaker-list {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   margin-top: 50px;
//   width: 100%;

//   -webkit-box-pack: start;
//   -ms-flex-pack: start;
//   justify-content: flex-start;
//   flex-flow: column;
//   @media screen and (max-width: 740px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: row;
//     flex-direction: row;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//   }
//   @media screen and (max-width: 480px) {
//     margin-top: 30px;
//   }
//   @media screen and (max-width: 414px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//   }
// }

// .company-list {
//   margin-left: -20px;
// }
// .breadcrumbs-container {
//   margin-bottom: 12px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   flex-wrap: wrap;
//   -ms-flex-wrap: wrap;

//   font-size: 18px;
//   font-weight: 500;
//   font-family: $title-text-font;
//   line-height: 20px;

//   a {
//     color: $primary-color;
//     display: inline-flex;
//     &:hover {
//       color: $primary-color;
//     }
//   }
//   .separator {
//     margin-top: 2px;
//     vertical-align: middle;
//     @media screen and (max-width: 414px) {
//       margin-top: 0;
//     }
//   }
//   .back-button {
//     font-size: 18px;
//     font-weight: 500;
//     font-family: $title-text-font;
//     line-height: 20px;
//     width: auto;
//     margin-left: 5px;
//     margin-right: 5px;
//     vertical-align: middle;
//     @media screen and (max-width: 740px) {
//       margin-bottom: 15px;
//       font-size: 16px;
//       svg {
//         height: 16px;
//         width: 16px;
//       }
//     }
//     @media screen and (max-width: 414px) {
//       span {
//         font-size: 12px;
//       }
//       svg {
//         height: 12px;
//         width: 12px;
//       }
//     }
//   }
// }

// #single-session-nav {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   @media screen and (max-width: 740px) {
//     margin-bottom: 0;
//     padding: 0 15px;
//   }
//   @media screen and (max-width: 414px) {
//     padding: 0 5px;
//   }
// }

// #single-poster-nav {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   @media screen and (max-width: 740px) {
//     margin-bottom: 0;
//     padding: 0 15px;
//   }
//   @media screen and (max-width: 414px) {
//     padding: 0 5px;
//   }
// }

// #agenda-title {
//   font-family: $title-text-font;
//   font-size: 48px;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.13;
//   letter-spacing: normal;
//   color: $primary-color;
//   text-transform: none;
// }

// .session-container {
//   margin-top: 50px;
//   width: 100%;
//   @media screen and (max-width: 1280px) {
//     width: 95%;
//     padding: 20px;
//   }
// }

// .single-social {
//   background-color: $brand-black;
//   border-radius: 50%;
//   width: 35px;
//   height: 35px;
//   display: inline-block;
//   margin-right: 10px;
//   img {
//     width: 35px !important;
//     height: 35px !important;
//     display: inline-block;
//   }

//   &:hover,
//   &:focus {
//     background-color: $brand-light-grey;
//   }
// }
// .exhibitor-social-links {
//   margin-bottom: 20px;
//   @media screen and (max-width: 640px) {
//     margin-bottom: 0px;
//   }

//   li {
//     display: inline-block;
//   }
// }

// .single-website {
//   color: $primary-color;
//   font-family: $title-text-font;
//   margin: 0 0 15px;
//   font-size: 16px;
//   line-height: 22px;
//   font-weight: 700;
//   display: block;
//   word-break: break-all;
//   &:hover {
//     color: $secondary-color;
//   }
//   @media screen and (max-width: 640px) {
//     margin-bottom: 25px;
//   }
// }

// .register-banner {
//   background-color: $secondary-color;
//   width: 100%;
// }

// .register-banner-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   max-width: 1325px;
//   margin: auto;
//   height: 175px;
//   h1 {
//     @media screen and (max-width: 1280px) {
//       font-size: 42px;
//     }
//     @media (max-width: 1200px) {
//       font-size: 36px !important;
//     }
//     @media screen and (max-width: 480px) {
//       font-size: 32px;
//     }
//   }
//   h2 {
//     @media (max-width: 1200px) {
//       font-size: 18px;
//     }
//   }
//   a:hover {
//     background-color: $primary-color !important;
//     color: $brand-white !important;
//   }
//   @media (max-width: 1200px) {
//     display: block;
//     padding: 20px;
//   }
//   @media screen and (max-width: 1180px) {
//     display: block;
//     padding: 20px;
//   }
// }

// .register-banner {
//   h1 {
//     color: $brand-white;
//     font-weight: 400;
//     font-size: 60px;
//     line-height: 1em;
//     font-family: $title-text-font;
//     font-weight: 700;
//     margin: 0;
//     text-transform: none;
//   }

//   h2 {
//     font-size: 23px;
//     line-height: 27px;
//     font-family: $title-text-font;
//     color: $brand-white;
//     font-weight: 400;
//   }
// }

// .event-app-banner-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   max-width: 1140px;
//   margin: auto;
//   height: 175px;
// }

// .download-buttons-containner {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   width: 500px;
//   margin-top: 25px;
// }

// .download-app-button {
//   margin: 0 35px;
//   cursor: pointer;
// }

// .footer-wrapper,
// .bottom-footer-wrapper {
//   width: 100%;
//   .footer-container,
//   .freeman-copyright-container {
//     box-sizing: border-box;
//     max-width: 1325px;
//     margin: 0 auto;
//   }
// }
// .footer-wrapper {
//   background-color: $primary-color;
//   color: $brand-white;
//   padding: 40px 0 100px;

//   @media screen and (max-width: 940px) {
//     padding-top: 20px;
//     padding-bottom: 40px !important;
//   }
// }
// .bottom-footer-wrapper {
//   background-color: $brand-gray;
//   color: $brand-grey;

//   font-family: $title-text-font;
//   font-size: 11px;
//   line-height: 1.45;

//   a {
//     color: $brand-grey;
//     text-decoration: underline;

//     &:hover {
//       color: $brand-white;
//     }
//   }

// .freeman-copyright-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   padding: 0 20px;
//   flex-direction: row;
//   padding: 50px 20px;

//   @media screen and (max-width: 940px) {
//     flex-direction: column;
//   }
// }

//   .footer-horizontal-nav {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     width: 100%;
//     -webkit-box-pack: start;
//     -ms-flex-pack: start;
//     justify-content: flex-start;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     flex-direction: row;
//     @media screen and (max-width: 380px) {
//       flex-direction: column;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//       text-align: center;
//     }
//   }
//   section {
//     &:first-of-type {
//       min-width: 220px;
//       @media screen and (max-width: 940px) {
//         -ms-flex-wrap: wrap;
//         flex-wrap: wrap;
//       }
//     }
//     li {
//       a {
//         margin-left: 5px;
//         margin-right: 5px;
//       }
//       &:last-of-type {
//         a {
//           margin-right: 0;
//         }
//       }
//       &:first-of-type {
//         a {
//           margin-left: 0;
//         }
//       }
//     }
//   }
// }

// .footer {
//   h1 {
//     color: $brand-white;
//     font-weight: 400;
//     font-size: 60px;
//     line-height: 1em;
//     font-family: $title-text-font;
//     font-weight: 700;
//     margin: 0;
//     text-transform: none;
//   }

//   .footer-container {
//     &:first-of-type {
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-pack: justify;
//       -ms-flex-pack: justify;
//       justify-content: space-between;
//       padding: 0 20px;
//     }
//     section {
//       &:first-of-type {
//         width: 220px;
//         @media screen and (max-width: 940px) {
//           -ms-flex-wrap: wrap;
//           flex-wrap: wrap;
//         }
//       }
//       &:nth-of-type(2) {
//         display: flex;
//         flex-flow: row wrap;
//         width: -webkit-max-content;
//         width: -moz-max-content;
//         width: max-content;
//         margin-bottom: 70px;
//         ul {
//           max-width: 175px;
//           margin-right: 50px;
//           &:last-of-type {
//             margin-right: 0;
//           }
//         }
//         li {
//           margin-bottom: 16px;
//           font-family: $title-text-font;
//           color: $brand-white;
//           line-height: 20px;
//           font-weight: 500;
//           font-size: 16px;
//           -webkit-transition: color 0.2s;
//           -o-transition: color 0.2s;
//           transition: color 0.2s;
//           a {
//             margin-bottom: 16px;
//             font-family: $title-text-font;
//             color: $brand-white;
//             line-height: 20px;
//             font-weight: 500;
//             font-size: 16px;
//             -webkit-transition: color 0.2s;
//             -o-transition: color 0.2s;
//             transition: color 0.2s;
//           }
//           &:hover,
//           a:hover {
//             color: $secondary-color;
//           }
//           h4 {
//             margin-bottom: 19px;
//             border-bottom: 1px solid $brand-white;
//             font-family: $title-text-font;
//             color: $brand-white;
//             font-size: 17px;
//             text-transform: none;
//             width: -webkit-max-content;
//             width: -moz-max-content;
//             width: max-content;
//             padding-bottom: 4px;
//           }
//         }
//         @media screen and (max-width: 1020px) {
//           max-width: 550px;
//           justify-content: flex-start;
//           ul {
//             width: 150px;
//             &:nth-of-type(3n) {
//               margin-right: 0;
//             }
//             &:last-of-type {
//               margin-right: 0;
//             }
//           }
//         }
//         @media screen and (max-width: 940px) {
//           margin-bottom: 20px;
//         }
//         @media screen and (max-width: 820px) {
//           max-width: 400px;
//           justify-content: flex-end;
//           ul {
//             margin-right: 0;
//             &:nth-of-type(2n - 1) {
//               margin-right: 50px;
//             }
//             &:last-of-type {
//               margin-right: 0;
//             }
//           }
//         }
//         @media screen and (max-width: 620px) {
//           width: 100%;
//           max-width: unset;
//           ul {
//             margin-right: 25px;
//             &:nth-of-type(3n) {
//               margin-right: 0;
//             }
//           }
//         }
//         @media screen and (max-width: 575px) {
//           width: 100%;
//           max-width: 410px;
//           ul {
//             margin-left: 15px !important;
//             margin-right: 15px !important;
//           }
//         }
//         @media screen and (max-width: 400px) {
//           flex-direction: column;
//           align-items: center;
//           text-align: center;
//           text-align: center;
//           li h4 {
//             width: auto;
//           }
//         }
//       }
//     }
//     @media screen and (max-width: 620px) {
//       flex-direction: column;
//     }
//     @media screen and (max-width: 575px) {
//       align-items: center;
//     }
//   }
// }

// .event-app {
//   padding: 30px 0 70px;
//   border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
//   max-width: 1140px;
//   margin: 0 auto;
// }

// .footer-social-item {
//   display: inline-block;
//   margin-right: 14px;
//   height: 30px;
//   width: 30px;
//   background-color: $brand-white;
//   border-radius: 50%;
//   -webkit-transition: background-color 0.2s;
//   -o-transition: background-color 0.2s;
//   transition: background-color 0.2s;
//   &:hover {
//     background-color: $brand-gray;
//     cursor: pointer;
//   }
// }

// .footer-social {
//   height: 30px;
//   width: 30px;
// }

// .footer-social-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-pack: distribute;
//   justify-content: space-around;
// }

// .footer-social img {
//   width: 30px;
//   height: 30px;
//   display: inline-block;
// }

// #footer-logo {
//   height: 60px;
//   display: block;
//   margin: 20px 0;
//   @media (max-width: 1200px) {
//     height: 50px;
//     margin: 20px 0;
//   }
//   @media screen and (max-width: 990px) {
//     height: 38px;
//     margin: 30px 0;
//   }
// }

// .footer-copy {
//   border-top: 1px solid hsla(0, 0%, 100%, 0.5);
//   max-width: 1325px;
//   margin: auto;
//   padding: 8px 0 0 0;
//   display: flex;
//   justify-content: flex-end;
//   li {
//     color: $brand-white;
//     font-family: $title-text-font;
//     line-height: 20px;
//     font-weight: 500;
//     font-size: 16px;
//     padding-top: 15px;
//     a {
//       color: $brand-white;
//       font-family: $title-text-font;
//       line-height: 20px;
//       font-weight: 500;
//       font-size: 16px;
//       padding-top: 15px;
//     }
//     @media screen and (max-width: 740px) {
//       text-align: center;
//     }
//   }
//   ul {
//     padding: 0 20px;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: justify;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     @media screen and (max-width: 740px) {
//       display: block;
//     }
//   }
// }

// .external-link {
//   border: 1px solid $brand-white;
//   height: 63px;
//   display: block;
// }

// .company-card {
//   border: 1px solid rgba(0, 0, 0, 0.16);
//   margin: 20px;
//   width: -webkit-max-content;
//   width: -moz-max-content;
//   width: max-content;
//   padding: 5px;
//   cursor: pointer;
//   @media screen and (max-width: 480px) {
//     margin: 10px;
//   }
// }

// .all-companies {
//   margin: 34px !important;
// }

// .company-image {
//   height: 160px;
//   width: 160px;
//   background-size: 95%;
//   background-repeat: no-repeat;
//   background-position: 50%;
//   margin-top: 0 !important;
//   @media screen and (max-width: 480px) {
//     height: 200px;
//   }
// }

// .companies-grid {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   @media screen and (max-width: 480px) {
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center !important;
//   }
// }

// .partners-grid {
//   -webkit-box-pack: start;
//   -ms-flex-pack: start;
//   justify-content: start;
// }

// .companies-banner {
//   margin: 40px 0 60px;
//   padding: 50px;
//   background-image: -o-linear-gradient(
//       top,
//       rgba(0, 19, 126, 0.39),
//       rgba(0, 19, 126, 0.39)
//     ),
//     url(https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df0344c82704c5a9ba8ea20_companieshero.jpg);
//   background-image: -webkit-gradient(
//       linear,
//       left top,
//       left bottom,
//       from(rgba(0, 19, 126, 0.39)),
//       to(rgba(0, 19, 126, 0.39))
//     ),
//     url(https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df0344c82704c5a9ba8ea20_companieshero.jpg);
//   background-image: linear-gradient(
//       180deg,
//       rgba(0, 19, 126, 0.39),
//       rgba(0, 19, 126, 0.39)
//     ),
//     url(https://assets-global.website-files.com/5de98c06bb83ab5540fc1c92/5df0344c82704c5a9ba8ea20_companieshero.jpg);
//   background-position: 0 40%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   min-height: 246px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   h2 {
//     margin: 0;
//     text-transform: none;
//     line-height: 60px;
//     font-size: 48px;
//     font-family: $title-text-font;
//     color: $brand-white;
//     font-weight: 400;
//     @media screen and (max-width: 740px) {
//       font-size: 34px;
//       line-height: 44px;
//     }
//   }
//   p {
//     font-family: $title-text-font;
//     color: $brand-white;
//     font-weight: 400;
//     margin-top: 34px;
//     margin-bottom: 44px;
//     font-size: 19px;
//     line-height: 30px;
//     max-width: 80%;
//     @media screen and (max-width: 740px) {
//       max-width: 100%;
//       margin-top: 20px;
//       margin-bottom: 20px;
//     }
//   }
//   @media screen and (max-width: 740px) {
//     min-height: 200px;
//     margin: 20px 0 30px;
//     padding: 20px;
//   }
// }

// .homepage-banner {
//   -webkit-box-pack: end;
//   -ms-flex-pack: end;
//   justify-content: flex-end;
//   height: 450px !important;

//   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//     height: 350px !important;
//   }
// }

// .banner-content {
//   width: 80%;
//   max-width: 1140px;
//   margin: 40px auto;
//   @media (max-width: 1380px) {
//     max-width: 1300px;
//   }
//   @media screen and (max-width: 1325px) {
//     max-width: 1200px;
//   }
//   @media screen and (max-width: 1280px) {
//     width: 85%;
//     max-width: 1100px;
//   }
//   @media screen and (max-width: 740px) {
//     width: 95%;
//   }
// }

// .rotating-banner {
//   position: relative;
//   width: 100%;
//   img {
//     height: auto;
//     width: 100%;
//   }
// }

// .large-banner {
//   height: 300px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   background-position: 50%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   padding: 0;
//   position: relative;
//   z-index: 5;

//   &.image-banner {
//     img {
//       width: 100%;

//       @media screen and (max-width: 1280px) {
//         margin-top: 0px;
//       }
//     }

//     @media screen and (max-width: 1280px) {
//       padding: 0;
//     }
//   }

//   h1 {
//     max-width: 1000px;
//     font-family: $title-text-font;
//     text-align: left;
//     text-transform: none;
//     color: $brand-white;
//     margin: 0;
//     font-weight: 700;
//     font-size: 54px;
//     @media screen and (max-width: 740px) {
//       font-size: 38px;
//     }
//     @media screen and (max-width: 640px) {
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//     }
//     @media screen and (max-width: 480px) {
//       text-align: center;
//     }
//   }
//   .sponsored-by {
//     font-size: 20px;
//     font-weight: 500;
//     max-width: 1000px;
//     font-family: $title-text-font;
//     text-align: left;
//     text-transform: none;
//     color: $brand-white;
//     margin: 0;
//     @media screen and (max-width: 640px) {
//       -webkit-box-align: center;
//       -ms-flex-align: center;
//       align-items: center;
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//     }
//     @media screen and (max-width: 414px) {
//       font-size: 13px;
//     }
//   }
//   h2 {
//     max-width: 60%;
//     font-size: 24px;
//     line-height: 32px;
//     margin-top: 15px;
//     margin-bottom: 25px;
//     font-weight: 400;
//     width: auto;
//     color: $brand-white;
//     font-family: $title-text-font;
//     @media screen and (max-width: 1325px) {
//       max-width: 70%;
//     }
//     @media screen and (max-width: 940px) {
//       max-width: 75%;
//     }
//     @media screen and (max-width: 740px) {
//       max-width: 100%;
//       font-size: 18px;
//       line-height: 24px;
//     }
//     @media screen and (max-width: 640px) {
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//     }
//     @media screen and (max-width: 480px) {
//       max-width: 90%;
//       font-size: 18px;
//       line-height: 24px;
//       margin-left: auto;
//       margin-right: auto;
//     }
//   }
//   img {
//     height: 20px;
//     margin-left: 10px;
//   }
//   .hashtag {
//     width: auto;
//     color: $brand-white;
//     font-family: $title-text-font;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: horizontal;
//     -webkit-box-direction: reverse;
//     -ms-flex-direction: row-reverse;
//     flex-direction: row-reverse;
//     max-width: 100%;
//     margin: 0;
//     font-size: 45px;
//     font-weight: 700;
//     font-stretch: normal;
//     font-style: italic;
//     line-height: 0.3;
//     letter-spacing: normal;
//     @media screen and (max-width: 940px) {
//       font-size: 32px;
//     }
//     @media screen and (max-width: 640px) {
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//       font-size: 32px;
//       -webkit-box-orient: horizontal;
//       -webkit-box-direction: normal;
//       -ms-flex-direction: row;
//       flex-direction: row;
//       margin-top: 25px;
//     }
//   }
//   button {
//     width: 300px;
//     font-size: 18px;
//     @media screen and (max-width: 740px) {
//       width: 200px;
//       font-size: 16px;
//     }
//     @media screen and (max-width: 480px) {
//       width: 200px;
//       font-size: 16px;
//       margin-right: auto;
//       margin-left: auto;
//     }
//   }

//   @media screen and (max-width: 740px) {
//     height: 300px;
//     padding-left: 15px;
//     padding-right: 15px;
//   }
//   @media screen and (max-width: 480px) {
//     text-align: center;
//   }

//   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//     background-size: 100%;
//   }
// }

// .search-icon {
//   display: block;
//   img {
//     height: 45px;
//     margin-bottom: 0;
//     @media screen and (max-width: 1024px) {
//       margin-bottom: 0;
//       margin-top: 20px;
//       margin-left: 30px;
//     }
//   }
// }

// .sponsor-label {
//   color: $primary-color !important;
//   text-transform: none;
//   font-size: 72px !important;
//   line-height: 1em;
//   margin: 0 0 10px 20px !important;
//   @media screen and (max-width: 740px) {
//     font-size: 42px !important;
//     line-height: 46px !important;
//   }
// }

// .loader {
//   min-height: 400px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   width: 100%;
//   img {
//     height: 200px;
//     display: block;
//     margin: auto;
//     @media screen and (max-width: 480px) {
//       height: 150px;
//     }
//   }

//   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//     justify-content: space-around;
//   }
// }

// .session-card-details {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;

//   -webkit-box-pack: justify !important;
//   -ms-flex-pack: justify !important;
//   justify-content: space-between !important;

//   -ms-flex-wrap: nowrap !important;
//   flex-wrap: nowrap !important;

//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;

//   .details-button {
//     @media screen and (max-width: 740px) {
//       margin-top: 10px !important;
//     }
//   }

//   &.session-card-details-only {
//     -webkit-box-pack: end !important;
//     -ms-flex-pack: end !important;
//     justify-content: flex-end !important;
//   }

//   h4 {
//     @media screen and (max-width: 414px) {
//       margin-bottom: 40px !important;
//     }
//   }
//   @media screen and (max-width: 740px) {
//     height: 140px;
//   }
//   @media screen and (max-width: 414px) {
//     margin-bottom: 15px;
//   }

//   @media screen and (max-width: 740px) {
//     display: block !important;
//   }
// }

// .error-message {
//   color: $primary-color;
//   font-size: 16px;
//   text-transform: none;
//   font-family: $title-text-font;
// }

// .livestream-container {
//   height: 350px;
//   width: 300px;
//   background: rgba(46, 44, 45, 0.025);
//   margin: 10px;
//   padding: 20px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   h1 {
//     color: $primary-color;
//     font-size: 20px;
//     font-family: $title-text-font;
//     margin-bottom: 10px;
//     line-height: 26px;
//     font-weight: 700;
//     &:hover {
//       color: $primary-color;
//     }
//   }
//   h3 {
//     font-family: $title-text-font;
//     margin-bottom: 0;
//     line-height: 22px;
//     font-weight: 700;
//     margin-right: 13px;
//     font-size: 16px;
//     display: block;
//     width: auto;
//     color: $primary-color;
//     text-transform: none;
//     &:nth-of-type(2) {
//       margin-top: 5px;
//       font-family: $title-text-font;
//       font-size: 16px;
//       font-weight: 400;
//       color: $brand-gray;
//     }
//   }
//   h4 {
//     font-family: $title-text-font;
//     font-size: 16px;
//     font-weight: 400;
//     color: $brand-gray;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.25;
//     letter-spacing: normal;
//     text-align: left;
//     margin-top: 0;
//     margin-bottom: 20px;
//   }
//   .session-card-details {
//     margin-top: 15px;
//   }
// }

// .livestream-title {
//   font-family: $title-text-font;
//   font-size: 48px;
//   font-weight: 700;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.21;
//   letter-spacing: normal;
//   color: $primary-color;
//   text-transform: none;
//   text-align: center;
//   @media screen and (max-width: 740px) {
//     font-size: 34px;
//   }
//   @media screen and (max-width: 640px) {
//     font-size: 24px;
//   }
// }

// .livestream-form {
//   width: 100%;
//   margin: auto auto 120px;
//   max-width: 1140px;
//   .error-message {
//     text-align: center;
//   }
//   form {
//     section {
//       width: 100%;
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       @media (max-width: 1100px) {
//         display: block;
//         padding: 0 20px;
//         width: auto;
//       }
//     }
//     div {
//       width: 100%;
//       &:first-of-type {
//         margin-right: 15px;
//       }
//       &:last-of-type {
//         margin-right: 0;
//       }
//     }
//   }
//   input {
//     height: 52px;
//     margin-bottom: 20px;
//     padding-right: 15px;
//     padding-left: 15px;
//     border-radius: 5px;
//     font-family: $title-text-font;
//     color: $brand-gray;
//     font-size: 16px;
//     line-height: 24px;
//     width: -webkit-fill-available;
//   }
//   select {
//     height: 52px;
//     margin-bottom: 20px;
//     padding-right: 15px;
//     padding-left: 15px;
//     border-radius: 5px;
//     font-family: $title-text-font;
//     color: $brand-gray;
//     font-size: 16px;
//     line-height: 24px;
//     width: -webkit-fill-available;
//     height: 72px;
//     @media screen and (max-width: 740px) {
//       height: 30px;
//       height: 56px;
//     }
//   }
//   button {
//     margin: 20px auto auto;
//   }
//   @media screen and (max-width: 1400px) {
//     max-width: 1100px;
//   }

//   @media screen and (max-width: 1280px) {
//     max-width: 1000px;
//   }
// }

// .livestream-form-label {
//   font-family: $title-text-font;
//   color: $primary-color;
//   font-size: 13px;
//   font-weight: 700;
//   text-align: left;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   display: block;
//   margin-bottom: 5px;
// }

// .search-container {
//   margin: 100px auto auto;
// }

// .search-wrapper {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   flex-direction: row;
//   margin: 30px 0;
//   @media screen and (max-width: 870px) {
//     width: 95%;
//     margin: 0px auto 20px;
//     flex-direction: column;
//   }
// }

// .search-input {
//   width: 600px;
//   border: 1px solid $primary-color;
//   outline: none;
//   font-size: 18px;
//   font-family: $title-text-font;
//   color: $brand-gray;
//   font-weight: 400;
//   margin: 0;
//   margin-right: 20px;
//   @media screen and (max-width: 870px) {
//     margin-bottom: 20px;
//   }
//   @media screen and (max-width: 740px) {
//     max-width: 250px;
//   }
//   @media screen and (max-width: 414px) {
//     max-width: 200px;
//   }
//   @media screen and (max-width: 320px) {
//     max-width: 180px;
//   }
// }

// .grid-padding {
//   width: 100%;
//   h5 {
//     margin-top: 0;
//     color: $brand-gray;
//     font-size: 54px;
//     margin-bottom: 40px;
//   }
// }

// .grid-padding h5 {
//   font-family: $title-text-font;
//   font-weight: 700;
//   text-transform: none;
//   @media screen and (max-width: 640px) {
//     font-size: 35px;
//   }
// }

// #search-header {
//   color: $primary-color;
//   font-size: 72px;
//   margin-bottom: 0;
//   margin-top: 150px;
//   font-family: $title-text-font;
//   font-weight: 700;
//   text-transform: none;
//   @media screen and (max-width: 640px) {
//     text-align: center;
//   }
// }

// #search-subheader {
//   font-family: $title-text-font;
//   font-weight: 700;
//   color: $primary-color;
//   font-size: 25px;
//   margin-bottom: 60px;
//   margin-top: 10px;
//   a:hover {
//     color: $primary-color;
//   }
// }

// .wrapper-no-margin {
//   margin: 0;
//   @media screen and (max-width: 1280px) {
//     margin: auto;
//   }
// }

// .grid-padding .companies-grid {
//   -webkit-box-pack: start;
//   -ms-flex-pack: start;
//   justify-content: flex-start;
// }

// .checkbox-container {
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   height: -webkit-max-content;
//   height: -moz-max-content;
//   height: max-content;
//   margin: 2px 0 !important;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   input {
//     height: -webkit-min-content;
//     height: -moz-min-content;
//     height: min-content;
//     margin: 0 10px 0 0;
//   }
//   label {
//     font-family: $title-text-font;
//     color: $brand-gray;
//     font-size: 16px;
//     line-height: 0;
//   }
//   @media screen and (max-width: 640px) {
//     width: 100% !important;
//   }
// }

// .checkbox-container-full-width {
//   width: 100% !important;
// }

// .hidden-label {
//   font-family: $title-text-font;
//   color: $primary-color;
//   font-size: 13px;
//   font-weight: 700;
//   text-align: left;
//   letter-spacing: 1px;
//   text-transform: none;
//   display: block;
//   margin-bottom: 5px;
//   width: 100%;
//   margin-top: 0;
// }

// .hidden-checkbox-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   padding-left: 22px;
//   width: auto !important;
//   margin-top: 8px;
//   margin-bottom: 8px;
//   div {
//     margin-top: 3px;
//   }
// }

// .checkbox-wrapper {
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   margin-bottom: 20px;
//   h1 {
//     font-family: $title-text-font;
//     color: $primary-color;
//     font-size: 13px;
//     font-weight: 700;
//     text-align: left;
//     letter-spacing: 1px;
//     text-transform: none;
//     display: block;
//     margin-bottom: 5px;
//     width: 100%;
//     margin-top: 0;
//   }
//   @media (max-width: 1100px) {
//     display: -webkit-box !important;
//     display: -ms-flexbox !important;
//     display: flex !important;
//   }
// }

// #other {
//   height: 20px;
//   border: none;
//   border-bottom: 1px solid $brand-light-grey;
//   border-radius: 0;
//   width: 47%;
// }

// .small-card {
//   background: $brand-off-white;
//   width: 250px;
//   min-height: 250px;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   margin: 10px;
//   padding: 30px;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-line-pack: center;
//   align-content: center;

//   div {
//     height: 230px;
//     width: 230px;
//     background: $brand-white;
//     margin: auto;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     display: -ms-grid;
//     display: grid;

//     img {
//       width: 100%;
//     }
//   }

//   h1 {
//     font-family: $title-text-font;
//     color: $primary-color;
//     font-weight: 900;
//     font-size: 18px;

//     /* font-size: 22px; */
//     text-align: left;
//     width: 230px;
//   }

//   h2,
//   h3 {
//     font-family: $title-text-font;
//     font-size: 15px;
//     margin: 0;
//     line-height: 20px;
//   }

//   h2 {
//     color: $brand-gray;
//     margin: 10px 0;
//     font-weight: 500;
//   }

//   h3 {
//     color: $primary-color;
//     font-weight: 900;
//   }

//   .details-button {
//     width: 50px;
//     padding: 10px 30px;

//     @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//       display: inline-table;
//     }
//   }
//   @media screen and (max-width: 740px) {
//     height: auto;
//     padding: 15px;
//   }
//   @media screen and (max-width: 480px) {
//     div img {
//       width: 90%;
//     }
//   }
//   @media screen and (max-width: 480px) {
//     padding: 10px;
//     width: 230px;
//     height: 250px;
//   }
// }

// .small-card-subsession {
//   width: 325px;
//   min-height: 138px;
//   border-radius: 4px;
//   background-color: $brand-off-white;
//   margin: 10px;
//   padding: 30px 30px 15px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-line-pack: center;
//   align-content: center;
//   h2 {
//     font-family: $title-text-font;
//     padding: 0;
//     margin: 0 0 30px 0;
//     font-size: 18px;
//     font-weight: 900;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.19 !important;
//     letter-spacing: normal;
//     text-align: left;
//     color: $primary-color;
//     width: 100%;
//     @media screen and (max-width: 480px) {
//       font-size: 14px;
//     }
//   }
//   h3 {
//     font-family: $title-text-font;
//     font-size: 14px;
//     font-weight: normal !important;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.71 !important;
//     letter-spacing: normal;
//     text-align: left;
//     color: $secondary-color !important;
//     text-transform: none !important;
//     @media screen and (max-width: 480px) {
//       font-size: 12px !important;
//     }
//   }
//   @media screen and (max-width: 700px) {
//     -ms-flex-wrap: nowrap !important;
//     flex-wrap: nowrap !important;
//   }
//   @media screen and (max-width: 480px) {
//     width: 200px;
//     height: 140px;
//     padding: 20px 20px 10px;
//   }
// }

// .large-card-subsession {
//   width: 275px;
//   height: 271px;
//   border-radius: 4px;
//   padding: 25px 25px 15px;
//   margin: 10px;
//   background-color: $brand-off-white;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   &:hover {
//     background-color: $brand-white;
//   }
//   button {
//     @media screen and (max-width: 414px) {
//       padding: 5px 10px;
//     }
//   }
//   h2 {
//     font-family: $title-text-font;
//     font-size: 20px;
//     font-weight: 900;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.14;
//     letter-spacing: normal;
//     text-align: left;
//     color: $primary-color;
//     margin: 0;
//     @media screen and (max-width: 414px) {
//       font-size: 14px;
//     }
//   }
//   .presenter-info {
//     margin: 0;
//     display: -webkit-box !important;
//     display: -ms-flexbox !important;
//     display: flex !important;
//     -webkit-box-orient: horizontal !important;
//     -webkit-box-direction: normal !important;
//     -ms-flex-direction: row !important;
//     flex-direction: row !important;
//     -ms-flex-wrap: nowrap !important;
//     flex-wrap: nowrap !important;
//     h3 {
//       font-family: $title-text-font;
//       font-size: 16px;
//       font-weight: normal;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.19;
//       letter-spacing: normal;
//       text-align: left;
//       color: $secondary-color;
//       text-transform: none;
//       &:nth-of-type(2) {
//         color: $brand-black;
//         font-size: 14px;
//         margin-top: 5px;
//       }
//       @media screen and (max-width: 740px) {
//         margin-left: 30px;
//       }
//       @media screen and (max-width: 414px) {
//         margin-left: 3px;
//         font-size: 12px;
//       }
//     }
//     section {
//       margin-left: 10px;
//     }
//     .subsession-speaker-image {
//       width: 70px;
//       height: 70px;
//       border-radius: 4px;
//       @media screen and (max-width: 740px) {
//         // margin: 20px;
//       }

//       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//         width: auto;
//       }
//     }
//     @media screen and (max-width: 740px) {
//       -webkit-box-align: center !important;
//       -ms-flex-align: center !important;
//       align-items: center !important;
//     }
//   }
//   @media screen and (max-width: 414px) {
//     width: 200px;
//     height: 200px;
//     margin-right: 0;
//   }
// }

// .grid-flex {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;

//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;

//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;

//   width: auto;
//   color: $brand-gray;
//   line-height: 1.5em;
//   font-family: $title-text-font;
//   font-size: 16px;
//   margin-bottom: 40px;
//   @media screen and (max-width: 740px) {
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//   }

//   .grid-item-full-width {
//     width: 100%;
//   }
// }

// .grid-filter-wrapper {
//   margin: 0;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   button {
//     padding: 15px;
//     width: 242px;
//     font-size: 16px;
//     //&:hover {
//     //  color: $brand-white !important;
//     //  border: 1px solid $primary-color !important;
//     //  background-color: $primary-color !important;
//     //}
//   }
//   .filter-button-active:hover {
//     color: $brand-white !important;
//     border: 1px solid $primary-color !important;
//     background-color: $primary-color !important;
//   }
//   button {
//     @media screen and (max-width: 640px) {
//       padding: 6px;
//     }
//   }
//   .filter-button {
//     @media screen and (max-width: 1280px) {
//       margin: 10px;
//     }
//   }
//   @media screen and (max-width: 640px) {
//     // display: block;
//   }
// }

// .grid-filter-wrapper:nth-of-type(2) {
//   margin: 30px auto;
//   button {
//     border: solid 1px $primary-color !important;
//     background-color: $brand-white !important;
//     border-radius: 2px !important;
//     &:hover {
//       border: solid 1px $primary-color !important;
//       background-color: $primary-color !important;
//       color: $brand-white !important;
//     }
//   }
// }

// .grid-filter-wrapper {
//   button.exhibitor-filter-button {
//     border: solid 1px $primary-color !important;
//     background-color: $brand-white !important;
//     border-radius: 2px !important;
//     &:hover {
//       border: solid 1px $primary-color !important;
//       background-color: $primary-color !important;
//       color: $brand-white !important;
//     }
//   }

//   button.poster-filter-button {
//     border: solid 1px $primary-color !important;
//     background-color: $brand-white !important;
//     border-radius: 2px !important;

//     &:hover {
//       border: solid 1px $primary-color !important;
//       background-color: $primary-color !important;
//       color: $brand-white !important;
//     }
//   }

//   button.poster-filter-button-active {
//     width: 242px;
//     //height: 40px;
//     border: solid 1px $primary-color !important;
//     background-color: $primary-color !important;
//     border-radius: 4px !important;
//     color: $brand-white !important;
//     padding: 0;
//   }
// }

// .grid-title {
//   font-family: $title-text-font;
//   margin-bottom: 15px;
//   font-size: 68px;
//   font-weight: 900;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.16;
//   letter-spacing: normal;
//   text-align: center;
//   color: $primary-color;
//   @media screen and (max-width: 705px) {
//     text-align: center;
//     margin-left: 0;
//     font-size: 34px;
//   }
//   @media screen and (max-width: 640px) {
//     font-size: 34px;
//   }
// }

// .modal {
//   height: 100vh !important;
//   width: 100% !important;
//   position: absolute;
//   top: 0;
//   background: rgba(0, 0, 0, 0.3);
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
// }

// input {
//   &::-webkit-input-placeholder {
//     color: rgba(0, 0, 0, 0.3);
//   }
//   &::-webkit-input-placeholder,
//   &:-ms-input-placeholder,
//   &::-ms-input-placeholder,
//   &::-moz-placeholder,
//   &::placeholder {
//     color: rgba(0, 0, 0, 0.3);
//   }
// }

// .scoped-search {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   margin: 30px 0;
//   input {
//     width: 600px;
//     margin-right: 20px;
//     border: 1px solid $primary-color;
//     outline: none;
//     font-size: 18px;
//     font-family: $title-text-font;
//     color: $brand-gray;
//     font-weight: 400;
//     padding-left: 10px;
//     @media screen and (max-width: 740px) {
//       max-width: 250px;
//     }
//     @media screen and (max-width: 414px) {
//       max-width: 200px;
//       margin-right: 10px;
//     }
//     @media screen and (max-width: 320px) {
//       max-width: 180px;
//       margin-right: 10px;
//     }
//   }
//   @media screen and (max-width: 870px) {
//     width: 95%;
//     margin: 0px auto 20px;
//   }
//   @media screen and (max-width: 740px) {
//     margin-bottom: 20px;
//   }
//   @media screen and (max-width: 640px) {
//     width: 95%;
//     margin: 0px auto 20px;
//   }
//   @media screen and (max-width: 414px) {
//     margin-bottom: 20px;
//   }
// }

// .ant-modal-content {
//   background: $brand-white;
//   padding: 50px;
//   position: relative;
// }

// .ant-modal-close {
//   position: absolute;
//   top: 5px;
//   right: 5px;
//   padding: 10px;
//   border: none;
//   &:hover {
//     cursor: pointer;
//   }
// }

// .register-title {
//   margin: 150px 0 50px;
//   text-align: center;
//   font-family: $title-text-font;
//   color: $brand-gray;
//   font-size: 45px;
//   font-weight: 900px;
// }

// .FAQ-wrapper {
//   font-family: $title-text-font;
//   text-transform: none;
//   width: 80%;
//   max-width: 1140px;
//   padding: 45px 0 120px;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-flex: 1;
//   -ms-flex: 1 1;
//   flex: 1 1;
//   margin: auto;
//   overflow: hidden;
//   height: auto;
//   @media screen and (max-width: 1280px) {
//     width: 90%;
//     margin-left: 4%;
//     padding: 25px 0 120px;
//   }
//   @media screen and (max-width: 740px) {
//     width: 95%;
//     margin-left: 1%;
//     padding: 25px 0 120px;
//   }
// }

// .FAQ-item {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   -webkit-box-align: start;
//   -ms-flex-align: start;
//   align-items: flex-start;
//   -webkit-box-flex: 1;
//   -ms-flex: 1 1;
//   flex: 1 1;
//   height: auto;
//   border-top: 1px solid $brand-light-grey;
//   padding: 0 29px;
//   margin: 0 1%;
//   color: $primary-color;
// }

// .FAQ-button {
//   font-size: 40px;
//   margin-top: 23px;
//   width: 30px;
//   height: 30px;
//   cursor: pointer;
//   z-index: 100;
//   @media (max-width: 1080px) {
//     margin-top: 13px;
//   }
//   @media screen and (max-width: 740px) {
//     margin-top: 9px;
//   }
// }

// .FAQ-question {
//   cursor: pointer;
//   z-index: 100;
//   padding-right: 5%;
//   width: 100%;
//   font-size: 24px;
//   line-height: 29px;
//   @media (max-width: 1080px) {
//     font-size: 18px;
//     line-height: 20px;
//   }
//   @media screen and (max-width: 740px) {
//     line-height: 20px;
//     font-size: 14px;
//   }
// }

// .FAQ-answer {
//   color: $brand-gray;
//   font-size: 16px;
//   line-height: 20px;
//   text-align: left;
//   padding-left: 35px;
//   width: 80%;
//   display: none;
//   max-height: 0;
//   -webkit-transition: all 0.3s ease-in-out;
//   -o-transition: all 0.3s ease-in-out;
//   transition: all 0.3s ease-in-out;
//   @media screen and (max-width: 740px) {
//     font-size: 14px;
//   }
// }

// .expand {
//   display: block;
//   max-height: 2000px;
//   padding-bottom: 45px;
//   margin-top: 20px;
// }

// .col {
//   width: 48%;
//   vertical-align: top;
//   display: inline-block;
//   padding: 0 1%;
//   @media (max-width: 1080px) {
//     width: 100%;
//   }
// }

// .FAQ-card {
//   &:first-child {
//     margin-top: -0.5px;
//   }
//   &:last-child {
//     border-bottom: 1px solid $brand-light-grey;
//   }
// }

// .left {
//   float: left;
//   @media (max-width: 1080px) {
//     float: none;
//   }
// }

// .right {
//   float: right;
//   @media (max-width: 1080px) {
//     float: none;
//   }
// }

// .iframe-wrapper {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 80%;
//   min-width: 300px;
//   min-height: 645px;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   margin: auto;
//   &.matchmaking {
//     max-width: 1000px;
//   }
// }

// .iframe-networking {
//   border: 0 !important;
// }

// .iframe-no-hero {
//   margin-top: 90px;
// }

// .np-flex-container {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   @media screen and (max-width: 990px) {
//     display: block;
//     margin-top: 30px;
//   }
//   @media screen and (max-width: 640px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//   }
// }

// .watch-now-button {
//   background-color: $secondary-color !important;
//   margin-left: 20px;
//   width: 120px;
//   border: 1px solid $secondary-color;
//   &:hover,
//   &:focus {
//     background-color: $brand-white !important;
//     color: $secondary-color !important;
//     border: 1px solid $brand-white !important;
//   }
//   &:first-of-type {
//     background-color: $primary-color !important;
//     border: 1px solid $primary-color !important;
//     &:hover,
//     &:focus {
//       background-color: $brand-white !important;
//       color: $primary-color !important;
//       border: 1px solid $brand-white !important;
//     }
//   }
//   @media (max-width: 1200px) {
//     width: 80px;
//   }
//   @media (max-width: 1080px) {
//     padding: 10px 15px;
//   }
// }

// .watch-now-close-button {
//   border: 1px solid $brand-white;
//   background-color: transparent;
//   &:hover {
//     border: 1px solid $brand-white !important;
//     background: $brand-white !important;
//     color: rgba(46, 44, 45, 0.9) !important;
//   }
//   @media (max-width: 1200px) {
//     width: 80px;
//   }
//   @media (max-width: 1080px) {
//     padding: 10px 15px;
//   }
// }

// .tintup-mobile {
//   display: none;
//   @media screen and (max-width: 990px) {
//     display: block;
//   }
// }

// .tintup-desktop {
//   @media screen and (max-width: 990px) {
//     display: none;
//   }
// }

// .single-live-stream-wrapper {
//   margin-top: 80px;
// }

// .live-stream-plugin-container {
//   margin: 10px 0;
//   width: 80%;
// }

// .live-stream-embed {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;
//   width: 100%;
//   margin-bottom: 50px;
//   .iframe-container {
//     position: relative;
//     width: 930px;
//     overflow: hidden;
//     padding-top: 56.25%;

//     .live-stream-embed-iframe-video {
//       position: absolute;
//       top: 0;
//       left: 0;
//       bottom: 0;
//       right: 0;
//       width: 100%;
//       height: 560px;
//       border: none;
//       @media screen and (max-width: 880px) {
//         height: 100%;
//       }
//     }
//     @media screen and (max-width: 1350px) {
//       width: 800px;
//       margin-left: 20px;
//     }
//     @media screen and (max-width: 1200px) {
//       width: 760px;
//       margin-left: 0;
//     }
//     @media screen and (max-width: 1100px) {
//       width: 740px;
//     }
//     @media screen and (max-width: 1040px) {
//       width: 100%;
//       margin-bottom: 20px;
//     }
//   }
//   #Live_Stream_Player {
//     width: 900px;
//     height: 550px;
//     @media screen and (max-width: 740px) {
//       width: 100%;
//       height: 400px;
//     }
//   }
//   .live-stream-plugin-container {
//     iframe {
//       box-sizing: border-box;
//       height: 500px;
//       border: 1px solid $brand-grey-2;
//       @media screen and (max-width: 740px) {
//         width: 100%;
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     margin-top: 10px;
//   }
// }

// .live-stream-sign-in {
//   width: 900px;
//   height: 550px;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   background-color: rgba(46, 44, 45, 0.9);
//   h1 {
//     padding: 0;
//     color: $secondary-color;
//     font-size: 27px;
//     font-family: $title-text-font;
//     line-height: 35px;
//     font-weight: 700;
//     text-align: center;
//     margin: 0 auto 30px;
//     max-width: 80%;
//     @media screen and (max-width: 480px) {
//       font-size: 18px;
//     }
//   }
//   a {
//     color: $brand-white;
//     &:hover {
//       color: $secondary-color;
//     }
//   }
//   h5 {
//     font-size: 18px;
//     line-height: 26px;
//     margin: 0 auto 40px;
//     font-family: $title-text-font;
//     color: $brand-white;
//     font-weight: 500;
//     max-width: 70%;
//     text-align: center;
//   }
//   h6 {
//     font-family: $title-text-font;
//     color: $brand-white;
//     font-weight: 500;
//     max-width: 70%;
//     text-align: center;
//     font-size: 13px;
//     margin: 0 auto;
//     line-height: 18px;
//   }
//   input {
//     border: none;
//   }
//   @media screen and (max-width: 1180px) {
//     width: 70%;
//   }
//   @media (max-width: 1080px) {
//     width: 60%;
//   }
//   @media screen and (max-width: 740px) {
//     width: 100%;
//   }
// }

// .live-stream-plugin-container {
//   width: 300px;
//   margin-left: 20px;
//   height: 550px;
//   background-position: 50%;
//   background-size: contain;
//   background-repeat: no-repeat;
//   // iframe {
//   //   width: 299px;
//   // }
//   @media screen and (max-width: 740px) {
//     margin: 50px 0;
//     width: 80%;
//   }
// }

// .map-title {
//   padding: 0.67em 0;
// }

// .filter-flex-wrapper {
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   margin: 0 15px 40px 15px;
// }

// .chatpage-wrapper {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
// }

// .chatpage-wrapper img {
//   height: 100%;
//   max-width: 1140px;
//   width: 100%;
// }

// .base-card-level {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;

//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;

//   -ms-flex-direction: column;
//   flex-direction: column;

//   -ms-flex-line-pack: center;
//   align-content: center;

//   -webkit-box-pack: justify;
//   -ms-flex-pack: justify;
//   justify-content: space-between;

//   background: $brand-off-white;
//   width: 170px;
//   height: 236px;
//   margin: 10px;
//   padding: 30px 30px 15px;

//   -webkit-transition: background-color 400ms ease;
//   -o-transition: background-color 400ms ease;
//   transition: background-color 400ms ease;

//   .card-image-container {
//     height: 175px;
//     width: 175px;

//     border: solid 1px $brand-white-5;
//     text-align: center;
//     vertical-align: middle;
//     background-color: $brand-white;
//     margin: 0;

//     &.no-sponsor-overlay {
//       .exhibitor-company-logo {
//         max-height: 100%;
//         max-width: 100%;
//         display: block;
//         position: relative;
//         top: 50%;
//         left: 50%;
//         -webkit-transform: translateY(-50%) translateX(-50%);
//         -ms-transform: translateY(-50%) translateX(-50%);
//         transform: translateY(-50%) translateX(-50%);
//       }
//     }

//     &.with-sponsor-overlay {
//       -webkit-box-pack: center;
//       -ms-flex-pack: center;
//       justify-content: center;
//       -webkit-box-align: center;
//       -ms-flex-align: center;
//       align-items: center;
//       display: flex;
//       position: relative;
//       .sponsor-level-image {
//         position: absolute;
//         top: 0;
//         right: 0;
//       }
//       .exhibitor-company-logo {
//         display: block;
//         width: 100%;
//         height: 100%;
//         width: initial;
//         height: initial;
//         max-width: 100%;
//         max-height: 100%;
//       }
//     }
//   }
//   h1 {
//     font-family: $title-text-font;
//     color: $brand-gray;
//     font-weight: 900;
//     font-size: 14px;
//     line-height: 18px;
//     text-align: left;
//     width: 175px;
//     margin: 0;
//     margin-top: 10px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
//   h2 {
//     font-family: $title-text-font;
//     font-size: 15px;
//     margin: 0;
//     line-height: 20px;
//     color: $brand-gray;
//     margin: 10px 0;
//     font-weight: 500;
//   }
//   h3 {
//     font-family: $title-text-font;
//     font-size: 15px;
//     margin: 0;
//     line-height: 20px;
//     color: $primary-color;
//     font-weight: 900;
//   }
//   .details-button {
//     width: 50px;
//     padding: 10px 30px;
//   }

//   &.extra-large-card-level {
//     width: 496px; // 536 - 40
//     padding: 20px;
//     .card-image-container {
//       width: 496px;
//     }

//     @media screen and (max-width: 570px) {
//       width: 310px; // 350 - 40
//       padding: 20px;
//       .card-image-container {
//         width: 310px;
//       }
//     }

//     @media screen and (max-width: 390px) {
//       width: 223px; // 263 - 40
//       padding: 20px;
//       .card-image-container {
//         width: 223px;
//       }
//     }

//     @media screen and (max-width: 290px) {
//       width: 159px; // 199 - 40
//       padding: 20px;
//       .card-image-container {
//         width: 159px;
//       }
//     }
//   }

//   &.large-card-level {
//     width: 310px; // 350 - 40
//     padding: 20px;
//     .card-image-container {
//       width: 310px;
//     }
//     @media screen and (max-width: 570px) {
//       width: 223px; // 263 - 40
//       padding: 20px;
//       .card-image-container {
//         width: 223px;
//       }
//     }

//     @media screen and (max-width: 390px) {
//       width: 159px; // 199 - 40
//       padding: 20px;
//       .card-image-container {
//         width: 159px;
//       }
//     }
//   }

//   &.medium-card-level {
//     width: 223px; // 263 - 40
//     padding: 20px;
//     .card-image-container {
//       width: 223px;
//     }
//     @media screen and (max-width: 570px) {
//       width: 159px; // 199 - 40
//       padding: 20px;
//       .card-image-container {
//         width: 159px;
//       }
//     }
//   }

//   &.small-card-level {
//     width: 159px; // 199 - 40
//     padding: 20px;
//     .card-image-container {
//       width: 159px;
//     }
//   }

//   &.extra-small-card-level {
//     width: 159px; // 199 - 40
//     height: 72px; // 112 - 40
//     padding: 20px;
//     justify-content: center !important;
//     h1 {
//       text-align: center;
//       width: 159px;
//     }
//   }
// }

// .exhibitor-level-row {
//   margin-bottom: 40px;
// }

// .grid-subtitle {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   margin-bottom: 10px;
//   .grid-level {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     margin: 5px;
//     font-size: 13px !important;
//     font-weight: bold !important;
//     font-stretch: normal;
//     font-style: normal;
//     color: $brand-light-grey;
//   }
//   img {
//     margin-right: 5px;
//   }
// }

// .chatpage-wrapper {
//   img {
//     height: 100%;
//     max-width: 1140px;
//     width: 100%;
//   }
//   .container {
//     margin-top: 0px;
//     padding: 20px 0px;
//   }
// }

// .redirect-wrapper {
//   width: 100%;
//   //height: 1003px;
//   background-image: -o-linear-gradient(top, $primary-color, $secondary-color);
//   background-image: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     from($primary-color),
//     to($secondary-color)
//   );
//   background-image: -o-linear-gradient(top, $primary-color, $secondary-color);
//   background-image: linear-gradient(
//     to bottom,
//     $primary-color,
//     $secondary-color
//   );
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;

//   .redirect-rectangle {
//     margin: 40px auto;
//     width: 495px;
//     -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
//     box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
//     background-color: $brand-white;
//     -ms-flex-item-align: center;
//     -ms-grid-row-align: center;
//     align-self: center;
//     @media screen and (max-width: 480px) {
//       width: 390px;
//     }
//     @media screen and (max-width: 414px) {
//       width: 330px;
//     }
//     @media screen and (max-width: 320px) {
//       width: 280px;
//     }

//     .redirect-form {
//       padding: 35px;
//     }

//     .details-button {
//       width: 100%;
//       padding-left: 0;
//       padding-right: 0;
//       margin: 13px 0;
//     }
//   }
// }

// .login-wrapper {
//   width: 100%;
//   height: 1003px;
//   background-image: -o-linear-gradient(top, $primary-color, $secondary-color);
//   background-image: -webkit-gradient(
//     linear,
//     left top,
//     left bottom,
//     from($primary-color),
//     to($secondary-color)
//   );
//   background-image: -o-linear-gradient(top, $primary-color, $secondary-color);
//   background-image: linear-gradient(
//     to bottom,
//     $primary-color,
//     $secondary-color
//   );
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -ms-flex-line-pack: center;
//   align-content: center;
// }

// .login-rectangle {
//   width: 495px;
//   -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
//   box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
//   background-color: $brand-white;
//   -ms-flex-item-align: center;
//   -ms-grid-row-align: center;
//   align-self: center;
//   @media screen and (max-width: 480px) {
//     width: 390px;
//   }
//   @media screen and (max-width: 414px) {
//     width: 330px;
//   }
//   @media screen and (max-width: 320px) {
//     width: 280px;
//   }
// }

// .form-logo {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   padding-top: 10px;
//   img {
//     width: 340px;
//     height: 61px;
//     -o-object-fit: contain;
//     object-fit: contain;
//     margin-top: 20px;
//     @media screen and (max-width: 480px) {
//       width: 300px;
//       height: 50px;
//     }
//     @media screen and (max-width: 414px) {
//       width: 280px;
//     }
//   }
//   @media screen and (max-width: 320px) {
//     width: 250px;
//   }
// }

// .login-title {
//   font-family: $title-text-font;
//   font-size: 24px;
//   font-weight: bold;
//   text-align: center;
//   color: $brand-gray;
// }

// .login-form-label {
//   font-family: $title-text-font;
//   color: $secondary-color;
//   font-size: 13px;
//   font-weight: 700;
//   text-align: left;
//   letter-spacing: 1px;
//   display: block;
//   margin-bottom: 5px;
// }

// .login-form-input {
//   width: 423px;
//   height: 53px;
//   padding-right: 5px;
//   padding-left: 5px;
//   font-size: 14px;
//   border-radius: 6px;
//   border: solid 1px rgba(46, 44, 45, 0.1);
//   background-color: $brand-white;
//   @media screen and (max-width: 480px) {
//     width: 320px;
//     height: 45px;
//   }
//   @media screen and (max-width: 414px) {
//     width: 260px;
//   }
//   @media screen and (max-width: 320px) {
//     width: 210px;
//     height: 40px;
//   }
// }

// .login-form-fields {
//   margin: 20px 30px;
// }

// .login-form-section {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   margin: 20px 30px;
//   > div {
//     -webkit-box-flex: 1;
//     -ms-flex: 1;
//     flex: 1;
//   }
//   @media screen and (max-width: 320px) {
//     font-size: 13px;
//   }
// }

// .login-button {
//   width: 100%;
//   border-radius: 3px;
//   -webkit-transition: border 0.2s, color 0.2s, background-color 0.2s,
//     opacity 0.2s;
//   -o-transition: border 0.2s, color 0.2s, background-color 0.2s, opacity 0.2s;
//   transition: border 0.2s, color 0.2s, background-color 0.2s, opacity 0.2s;
//   &:disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// }

// .logout-login-button {
//   border: solid 1px $primary-color;
//   background-color: $primary-color;
//   color: $brand-white;
//   padding: 20px 0;
//   text-align: center;
// }

// .login-submit-button {
//   height: 52px;
//   border: solid 1px $secondary-color;
//   background-color: $secondary-color;
//   color: $brand-white;
// }

// .login-form-register {
//   border: solid 1px $primary-color;
//   background-color: $primary-color;
//   color: $brand-white;

//   margin-top: 1.5rem;
//   padding: 20px 0;
//   text-align: center;
// }

// .login-form-forgot {
//   border: solid 1px $brand-light-grey;
//   background-color: $brand-light-grey;
//   color: $brand-white;

//   margin-top: 1.5rem;
//   padding: 20px 0;
//   text-align: center;
// }

// .login-buttons {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   margin: 30px;

//   &.stack {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//   }
// }

// .login-form-forgot-message {
//   margin: 0 30px;
// }

// .login-form-notes {
//   color: $brand-light-grey;
//   font-size: 12px;
//   line-height: 1.5;
//   margin: 0 30px;
//   padding-bottom: 20px;
//   a {
//     color: $brand-light-grey;
//     text-decoration: underline;
//   }
// }

// .login-checkbox-label {
//   padding: 5px;
// }

// .login-forgot-password {
//   text-align: end;
// }

// .login-forgot-password a:hover,
// .login-form-notes a:hover {
//   color: $secondary-color;
// }

// .login-form-message-error {
//   color: $brand-warning;
//   font-weight: bold;
//   font-size: 12px;
//   text-transform: uppercase;

//   p {
//     margin-top: 5px;
//   }
// }

// .sponsor-page {
//   .large-banner {
//     @media screen and (max-width: 740px) {
//       height: 300px;
//     }
//     h1 {
//       @media screen and (max-width: 740px) {
//         margin-top: 50px;
//       }
//       @media screen and (max-width: 414px) {
//         font-size: 22px;
//         margin-top: 5px;
//       }
//     }
//   }
//   .image-banner {
//     @media screen and (max-width: 740px) {
//       padding: 0;
//     }
//   }
//   a:last-of-type {
//     @media screen and (max-width: 740px) {
//       margin: 0 auto auto;
//     }
//   }
// }

// .session-grid-padding {
//   @media screen and (max-width: 640px) {
//     margin-top: 100px;
//   }
// }

// .search-page-wrapper {
//   .search-error {
//     font-size: 2em;
//     margin: 0.67em 0;
//     color: $secondary-color;
//     text-align: center;
//   }
//   @media screen and (max-width: 640px) {
//     width: 90%;
//   }
// }

// .banner-content h1 {
//   @media screen and (max-width: 414px) {
//     font-size: 26px;
//   }
// }

// .live-stream-session-container-section {
//   h1 {
//     @media screen and (max-width: 480px) {
//       text-align: left;
//       padding: 5px;
//       padding-left: 0;
//       margin: 0;
//     }
//   }
//   h3 {
//     @media screen and (max-width: 480px) {
//       text-align: left;
//       padding: 5px;
//       padding-left: 0;
//       margin: 0;
//     }
//   }
//   @media screen and (max-width: 480px) {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//   }
//   article {
//     @media screen and (max-width: 480px) {
//       padding: 12px 12px 23px 12px;
//     }
//   }
//   .speaker-list {
//     @media screen and (max-width: 480px) {
//       display: -webkit-box;
//       display: -ms-flexbox;
//       display: flex;
//       -webkit-box-orient: vertical;
//       -webkit-box-direction: normal;
//       -ms-flex-direction: column;
//       flex-direction: column;
//       -webkit-box-pack: justify;
//       -ms-flex-pack: justify;
//       justify-content: space-between;
//     }
//   }
// }

// .modal-checkbox-label {
//   @media screen and (max-width: 320px) {
//     font-size: 12px;
//   }
// }

// .poster-info {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   padding: 20px 30px;
//   max-width: 690px;
//   padding-top: 0;
//   @media screen and (max-width: 940px) {
//     max-width: 490px;
//   }
//   @media screen and (max-width: 740px) {
//     padding: 0;
//   }
// }

// .live-stream-container * {
//   font-family: $chat-text-font !important;
// }

// .tabs {
//   div.tab-content {
//     background-color: $brand-white;
//     height: 520px;
//     // padding: 10px;
//   }
// }

// .tab-list {
//   border-bottom: 1px solid $brand-white-6;
//   padding-left: 0;
//   display: flex;
//   justify-content: center;
// }

// .tab-list-item {
//   width: 186.4px;
//   //height: 38.3px;
//   display: inline-block;
//   margin-bottom: -1px;
//   padding: 0.5rem 0.75rem;
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   text-align: center;
//   color: $primary-color !important;
//   cursor: pointer;
//   background-color: rgba(0, 0, 0, 0.15);
//   border-radius: 10px 10px 0 0;

//   &:hover {
//     border: solid $primary-color;
//     border-width: 0 0 3px 0;
//   }

//   a {
//     color: $primary-color !important;
//   }

//   @media screen and (max-width: 375px) {
//     max-width: 170px;
//   }

//   @media screen and (max-width: 320px) {
//     max-width: 140px;
//   }
// }

// .tab-list-active {
//   background-color: $brand-white;
//   border: 1px solid $brand-grey-2;
//   border-bottom: none;
//   // border-width: 0 0 3px 0;

//   &:hover {
//     border: 1px solid $brand-grey-2;
//     border-bottom-color: $primary-color;
//     border-bottom-width: 3px;
//   }
// }

// .transparent-bg {
//   background: transparent;
//   box-shadow: none;

//   .form-logo {
//     display: none;
//   }

//   .login-title {
//     color: $brand-white;
//     text-align: left;
//     font-size: 60px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1;
//     letter-spacing: normal;
//     font-weight: bold;
//     margin-left: 30px;
//   }

//   .login-form-notes {
//     color: $brand-white;
//     a {
//       color: $brand-white;
//     }
//   }
// }

// .custom-addeventatc-drop {
//   // display: none !important;
//   visibility: hidden !important;
//   height: 0 !important;
//   width: 0 !important;
//   overflow: hidden !important;
//   outline: none !important;
// }

// .now-playing-video-drawer {
//   width: 350px;
//   height: 196px;
//   -o-object-fit: contain;
//   object-fit: contain;
//   border-radius: 6px;
//   border: solid 2px $primary-color;
//   background-color: $brand-black;
//   position: fixed;
//   bottom: 195px;
//   left: 0;
//   z-index: 200;
//   -webkit-transform: translateX(-100%);
//   -ms-transform: translateX(-100%);
//   transform: translateX(-100%);
//   -webkit-transition: -webkit-transform 0.3s ease-out;
//   transition: -webkit-transform 0.3s ease-out;
//   -o-transition: transform 0.3s ease-out;
//   transition: transform 0.3s ease-out;
//   transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;

//   #close-icon {
//     top: 10px;
//   }

//   #expand-icon {
//     top: 50px;
//   }

//   &.open {
//     -webkit-transform: translateX(0%);
//     -ms-transform: translateX(0%);
//     transform: translateX(0%);
//     bottom: 195px;
//     left: 40px;

//     @media screen and (max-width: 768px) {
//       bottom: 225px;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     bottom: 190px;
//   }

//   @media screen and (max-width: 500px) {
//     display: none;
//   }
// }

// #close-icon,
// #expand-icon,
// iframe.now-playing-video {
//   position: absolute;
// }

// #close-icon,
// #expand-icon {
//   cursor: pointer;
//   right: 6px;
// }

// .drawer-video-play {
//   width: 107px;
//   height: 60px;
//   border-radius: 6px;
//   border: solid 2px $primary-color;
//   background-repeat: no-repeat;
//   background-color: $brand-black;
//   background-position: center;
//   background-image: url("/images/icons/video-play.svg");
//   cursor: pointer;
// }

// .fluent-preview-close {
//   background-color: transparent;
//   border: none;
//   top: 55px;
//   right: -200px;
// }

// .fluent-close-button {
//   border-color: transparent;
// }

// .iframe-wrapper-unsupported {
//   margin: 30px auto;
//   text-align: center;
//   font-size: 25px;
//   font-weight: bold;
//   padding: 20px;
// }

// .details-filter-button {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   width: 190px;
//   margin-right: 0;
//   margin-bottom: 10px;
//   padding: 15px 30px;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: row;
//   flex-direction: row;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-flex: 0;
//   -ms-flex: 0 1 auto;
//   flex: 0 1 auto;
//   border-radius: 2px;
//   font-family: $title-text-font;
//   font-size: 15px;
//   line-height: 20px;
//   font-weight: 500;
//   text-align: center;
//   text-shadow: none;
//   cursor: pointer;
//   text-decoration: none;
//   -webkit-transition: border 0.2s, color 0.2s, background-color 0.2s;
//   -o-transition: border 0.2s, color 0.2s, background-color 0.2s;
//   transition: border 0.2s, color 0.2s, background-color 0.2s;
//   margin-top: 0 !important;
//   white-space: nowrap;
//   margin-right: 15px;
//   margin-left: 15px;

//   &:hover {
//     @media screen and (max-width: 740px) {
//       border: 0;
//     }
//   }
//   @media screen and (max-width: 940px) {
//     font-size: 15px;
//     padding: 15px;
//     width: 140px;
//   }
//   @media screen and (max-width: 740px) {
//     border: 0;
//     width: 180px;
//     padding: 5px;
//   }
//   @media screen and (max-width: 480px) {
//     border: 0;
//     width: 180px;
//     padding: 5px;
//   }

//   background-color: $brand-white;
//   border: 1px solid $primary-color;
//   color: $primary-color;

//   &:hover {
//     background-color: $primary-color !important;
//     color: $brand-white !important;
//   }

//   &.details-filter-button-active {
//     background-color: $primary-color !important;
//     color: $brand-white !important;

//     &:hover,
//     &:focus {
//       background-color: $primary-color !important;
//       color: $brand-white !important;
//     }
//   }
// }

// .representative-wrapper {
//   .representative-header {
//     font-size: 20px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.1;
//     letter-spacing: normal;
//     text-align: right;
//     color: $primary-color;
//     border-bottom: 1px solid $primary-color;
//   }
// }

// .search-results-header {
//   font-weight: 700;
//   text-transform: none;
//   margin-top: 0;
//   color: $brand-gray;
//   font-size: 54px;
//   margin-bottom: 40px;
// }

// @import "header"; //header.scss
// @import "tiles"; //tiles.scss
// @import "posters"; //posters.scss
// @import "speakers"; //speakers.scss
// @import "sessions"; //sessions.scss
// @import "now-playing"; //now-playing.scss
