$primary-color: #563d82;
$secondary-color: #001a70;
$tertiary-color: #e03100;
$brand-blue: #009cde;
$brand-green: #44883e;
$brand-green-2: #43b02a;
$brand-orange: #ffa300;
$brand-yelow: #ffcd00;
$brand-dark-red: #af272f;
$brand-gray: #2e2c2d;
$brand-gray-2: #7c878e;
$brand-light-grey: #7f7f7f;
$brand-light-grey-2: #767676;
$brand-grey: #bbbbbb;
$brand-grey-2: #707070;
$brand-grey-3: #c7c7c7;
$brand-grey-4: #4c5b6d;
$brand-blue: #563d82;
$brand-blue-2: #001a70;
$brand-off-white: rgba(0, 0, 0, 0.04);
$brand-white: #ffffff;
$brand-white-2: #fafafa;
$brand-white-3: #f0f0f0;
$brand-white-4: #cecece;
$brand-white-5: #e6e7e8;
$brand-white-6: #ccc;
$brand-white-7: #eee;
$brand-black: black;
$title-text-font: "TradeGothicLTStd", sans-serif;
$title-text-font-2: "AkzidenzGroteskBE-BoldCn", sans-serif;
$title-text-font-3: "AkzidenzGroteskBE-Bold", sans-serif;
$chat-text-font: "Roboto", sans-serif;
$chat-emoji-background: #f4f4f4;
$brand-warning: #ffd94d;
$desktopHeight: 81px;
$maxBaseWidth: 1355px;
$expandEase: cubic-bezier(0.65, 0, 0.35, 1);
$easeInOutCirc: cubic-bezier(0.85, 0, 0.15, 1);
$bodyMinHeight: 50vh;
$brand-red: #ff0000;

$breakpoints: (
  "phone-small": 320px,
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "midpoint": 980px,
  "tablet-wide": 1024px,
  "laptop-wide": 1080px,
  "laptop": 1200px,
  "desktop": 1280px,
  "desktop-wide": 1500px,
);
