@import "../../../css/core";
@import "./header-mixins";

.headerContainer {
  padding: 10px 30px;
  border-bottom: 1px solid #0066b3;

  &.preEvent {
    padding: 0;
    border: 0;
  }
}

.headerInterior {
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1800px;

  .headerLogoWrapper {
    margin: 0;
  }
}

.headerInteriorRight {
  display: flex;
  flex-flow: row;
  align-items: center;

  .mainNav {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
}

.headerLabel {
  @include headerLabel;
  border: 1px solid $brand-white;
  border-bottom: solid 5px transparent;
  &:focus {
    border-bottom: solid 5px #563d82;
  }

  div {
    cursor: pointer;
    color: $brand-black;
  }
}

.headerLabelButton {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: solid 5px transparent;

  // &:focus {
  //   background-color: $brand-off-white;
  // }
}

.mainLink {
  @include dropDownTitle();
  position: relative;
  bottom: 3px;
}

.dropDownTitle {
  @include dropDownTitle();
  @include dropDownCaret;
}

.inactiveDropdown {
  display: none;
}

.activeDropdown {
  @include activeDropdown();

  position: absolute;
  padding: 20px;
  left: -10px;
  top: 70px;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  z-index: 50;
  line-height: 1;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.49);
  border: solid 1px $brand-grey-2;
}

.dropdownLink {
  padding: 10px 0;
  display: block;

  li {
    padding: 5px 0;

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  a {
    display: block;
    color: $brand-black;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.iconHolder {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 30px;
}
