@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFSup.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Materialdesignicons webfont;
  src: url("/fonts/materialdesignicons-webfont.woff2") format("woff2"),
    url("/fonts/materialdesignicons-webfont.eot") format("embedded-opentype"),
    url("/fonts/materialdesignicons-webfont.woff") format("woff"),
    url("/fonts/materialdesignicons-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFXBdIt.otf") format("otf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFBol.otf") format("otf"),
    url("/fonts/AkzidGroCFFBolEx.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFLigEx.otf") format("otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFBolEx.otf") format("otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFXBdIt.otf") format("otf"),
    url("/fonts/AkzidGroCFFXBd.otf") format("otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFIt.otf") format("otf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFLig.otf") format("otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFLigIt.otf") format("otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFLigExIt.otf") format("otf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFReg.otf") format("otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: AkzidenzGroteskBE-CnIt;
  src: url("/fonts/AkzidGroCFFXBdCnIt.otf") format("otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz Grotesk;
  src: url("/fonts/AkzidGroCFFMd.otf") format("otf");
  font-weight: 500;
  font-style: normal;
}

// temporary font files
@font-face {
  font-family: TradeGothicLTStd;
  src: url("/fonts/AkzidGroCFFMd.otf") format("otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: AkzidenzGroteskBE-BoldCn;
  src: url("/fonts/AkzidGroCFFXBdCnIt.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: AkzidenzGroteskBE-Bold;
  src: url("/fonts/AkzidGroCFFXBdCnIt.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}