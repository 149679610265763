@import "../../../css/core";

.pdfLinks {
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 10px;

  .linkTitle {
    line-height: 16px;
    margin: 0 0 0 10px;
    font-weight: 700;
    color: $tertiary-color;

    &:hover {
      color: $secondary-color;
    }
  }
}

.productContainer {
  margin: 50px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  .products {
    align-content: center;
    margin: 20px 0 20px 25px;
    img {
      max-width: 200px;
      height: auto;
    }
    h6 {
      margin: 5px 0 25px 0 !important;
    }
  }
}
