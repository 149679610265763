@import "../../../css/core";

.representativeWrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 15px;
  font-family: $title-text-font;
  @include mq("midpoint") {
    align-items: flex-end;
  }

  h2 {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    line-height: 1.5;
    font-size: 20px;
    text-transform: uppercase;
    text-align: right;
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
    margin-bottom: 10px;
  }

  > ul {
    margin-top: 5px;
  }
}
