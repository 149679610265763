.matrix {
  width: 100%;
  max-width: 1080px;
  margin: 80px auto;
  tr {
    td {
      padding: 12px 10px;
    }
    &:nth-child(even) {
      td {
        background: #e1e1e1;
      }
    }
  }
}
