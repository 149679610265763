@import "../../../css/core";

.linkList {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkListItem {
  @include socialIcons();
  margin-right: 25px;
  background-color: $tertiary-color;
}

.linkListItemPrimary {
  @include socialIcons($tertiary-color, 25px, rgba($tertiary-color, 0.7));
  margin-right: 25px;
}
