$primary-color: #00a0df;
$secondary-color: #742f8a;
$tertiary-color: #77bd43;
$quaternary-color: #002e6d;
$quinary-color: #0a4161;
$senary-color: #414042;
$brand-blue: #1a365d;
$brand-blue-bg: #90badb; // rgba(132, 179, 215, 0.9);
$brand-red: #e02926;
$brand-peach: #c24f45;
$brand-silver: #5c7e95;
$brand-yellow: #f4a81d;

$brand-gray: #2e2c2d;
$brand-gray-1: #5a5a5a;
$brand-light-grey: #7f7f7f;
$brand-light-grey-2: #767676;
$brand-grey: #bbbbbb;
$brand-grey-2: #707070;
$brand-grey-3: rgba(65, 64, 66, 0.5);
$brand-grey-4: rgba(0, 0, 0, 0.25);
$brand-grey-5: #5a5a5a;
$brand-off-white: #f5f5f5; //rgba(5, 4, 4, 0.04);
$brand-white: #ffffff;
$brand-white-2: #fafafa;
$brand-white-3: #f0f0f0;
$brand-white-4: #cecece;
$brand-white-5: #e6e7e8;
$brand-white-6: #ccc;
$brand-black: black;
$brand-freeman-black: #141414;
$card-box-shadow: rgba(0, 0, 0, 0.25);

$title-text-font: "Poppins", sans-serif;
$chat-text-font: "Poppins", sans-serif;
$text-font: "Poppins", sans-serif;
.single-exhibitor-light-theme,
.single-exhibitor-dark-theme {
  .content-wrapper-with-contact {
    padding-top: 47px;
  }

  .content-wrapper-with-contact h4,
  .representative-wrapper .representative-header {
    font-family: $text-font;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    margin: 1.33em 0;
  }

  .single-container-right {
    .single-list-details {
      li {
        font-weight: normal;
        font-size: 16px;
      }
    }
  }
}
.single-exhibitor-light-theme {
  background-color: $brand-white;
}
.single-exhibitor-dark-theme {
  background-color: #171516;

  .breadcrumbs-container {
    background-color: #171516;
  }
}

.single-exhibitor-dark-theme {
  .single-exhibitor-mobile {
    display: none;
  }
  .single-exhibitor-booth {
    #booth-intro-video-container {
      background: #2e2c2d;
    }
  }
}
.single-exhibitor-booth {
  position: relative;
  margin-bottom: 38px;
  #booth {
    display: block;
    .interactive-element {
      cursor: pointer;
      .glow {
        opacity: 0;
      }
      &:hover {
        .glow {
          opacity: 1;
        }
      }
    }
  }
  #booth-intro-video-container {
    background: $brand-white;
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    &:before {
      content: "";
      display: block;
      padding-top: 9/16 * 100%;
      width: 100%;
    }
    &.hidden {
      display: block !important;
      visibility: hidden;
    }
    #intro-video {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }
}

.booth-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2%;
  .booth-modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .booth-modal {
    position: relative;
    max-width: 1200px;
    .booth-modal-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .booth-modal-close-button {
        background: transparent;
        color: $brand-white;
        border: none;
      }
    }
    .booth-modal-content {
      background-image: url(/images/loader.gif);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75px;
      max-width: 100%;
      min-height: 200px;
      min-width: 200px;
      img,
      video {
        display: block;
        background-color: #c7c7c7;
      }
      img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: calc(100vh - 96px);
      }
      .booth-video-container {
        width: 90vw;
        max-width: 1200px;
        position: relative;
        padding-top: 9/16 * 100%;
        @media screen and (max-width: 959px) and (orientation: landscape) {
          width: 70vw;
        }
        @media screen and (min-width: 1280px) {
          width: 96vw;
        }
        .play-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.25);
          right: 0;
          button {
            height: auto;
            max-width: 14%;
            color: $brand-white;
            width: 135px;
            background: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: none;
          }
          &.hidden {
            display: none;
          }
        }
        video {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}
