@import "../../../css/core";
@import "../../Livestream/scss/livestream-mixins";

.sideBar {
  box-sizing: border-box;
  margin-top: 5px;
  width: 100%;
  padding: 5px;
  background-color: transparent;

  @include mq("tablet") {
    width: 330px;
    background-color: $brand-white-2;
    padding: 40px 40px 40px 36px;
  }

  h1 {
    @include sessionTitle;
    font-size: 32px;
    padding:0;
    line-height:34px;
  }

  h2 {
    font-family: $title-text-font;
    line-height: 24px;
    font-weight: 700;
    font-size: 18px;
    display: block;
    width: auto;
    margin: 0 0 20px 0;
    color: $brand-gray;
    white-space: nowrap;
  }

  .presenterHolder {
    display: flex;
    align-items: center;
    padding: 10px 0 0 0;

    div {
      width: 70px;
      height: 70px;
      margin-right: 20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    h3 {
      font-family: $title-text-font;
      font-size: 16px;
      line-height: 1.19;
      margin: 0;
      color: $brand-gray;
    }

    h4 {
      font-family: $title-text-font;
      font-size: 12px;
      color: $brand-gray;
      margin: 0;
      line-height: 1.25;
    }
  }

  .pdfLink {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 10px;

    span {
      margin: 0 0 0 10px;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  .disclosuresImg {
    margin-top: 10px;
    height: 70px;
  }
}

.sessionMeta {
  display: block;
  margin-bottom: 30px;
  width: 100%;

  h2 {
    font-family: $title-text-font;
    line-height: 22px;
    font-weight: 700;
    font-size: 20px;
    display: block;
    width: auto;
    text-transform: none;
    margin: 0;
    color: $primary-color;
    white-space: nowrap;
    border-bottom: 1px solid $primary-color;
    padding-bottom: 4px;
  }

  p {
    margin: 2px 0 0 0;
    color: $brand-black;
    font-family: $title-text-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  li {
    margin: 2px 0 0 0;
    color: $brand-black;
    font-family: $title-text-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
  }

  button {
    @include alternateButton();

    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.companyImage {
  height: 238px;
  max-width: 244px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $brand-white;
  margin: 0 auto;
  @include mq("tablet") {
    width: 244px;
  }
  @include mq("phone-wide", max) {
    max-width: 200px;
    height: 200px;
  }
}

.companyImageLink {
  display: block;
}

.calendarHolder {
  margin-bottom: 20px;
}

.supplementalFileName {
  word-break: break-all;
}
