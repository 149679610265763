@import "../../../css/core";
@import "../../Livestream/scss/livestream-mixins";

.hero {
  background-size: cover;
  padding-left: 30px;
  padding-right: 30px;

  .main {
    background: $brand-white;
    margin-top: 0px;
    margin-bottom: 0;
    padding-top: 34px;
    padding-bottom: 34px;
    @include mq("midpoint") {
      margin-top: 100px;
    }
  }
}

.main {
  @include mainPageSetting(34px);
  @include mq("midpoint") {
    margin-top: 100px;
  }
}

.scheduleMeetingContainer {
  margin-top: 20px;
  display: flex;
  max-width: 100%;
  justify-content: flex-start;
  @include mq("midpoint")
  {
    justify-content: flex-end;
  }

  .detailsButton {
    @include primaryButton;

    width: 157px;

    &.inactive {
      background-color: $brand-light-grey;
      border: unset;

      &:hover:not(:disabled) {
        background-color: $brand-light-grey;
        color: $brand-white;
        border: unset;
        cursor: default;
      }
    }
  }
}

.singlePageTopImg {
  display: block;
  margin: 0 auto 10px;
  width: 100%;
  flex-shrink: 0;
  @include mq("phablet")
  {
    margin-bottom: 40px;
  }
}

.singleSpeakerContainer {
  @include singleSpeakerContainer;
}

.content {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  @include mq("tablet") {
    padding-left: 30px;
    padding-right: 30px;
  }

  h1 {
    font-family: $title-text-font-2;
    color: $primary-color;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.13;
    margin: 0;
    @include mq("midpoint") {
      font-size: 60px;
     
    }
  }

  p {
    margin-top: 24px;
    color: $brand-black;
    line-height: 1.5em;
    font-weight: 400;
    font-family: $title-text-font-2;
    font-size: 16px;
  }
}

.singleWebsite {
  color: $tertiary-color;
  font-family: $title-text-font-2;
  margin: 0 0 25px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: block;
  word-break: break-all;
  &:hover {
    color: $secondary-color;
  }
  @media screen and (max-width: 640px) {
    margin-bottom: 25px;
  }
}

.exhibitorSocialLinks {
  @include mq("tablet-small") {
    margin-bottom: 20px;
  }
}

.contentWrapperWithContact {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @include mq("midpoint") {
    flex-flow: row;
  }
}

.leftDetails {
  width: 100%;

  @include mq("midpoint") {
    width: 40%;
  }
}

.singleListDetails {
  width: 100%;

  @include mq("midpoint") {
    width: 40%;
  }

  a {
    font-weight: 700;
    font-size: 16px;
    color: $tertiary-color;
  }

  li {
    color: $brand-black;
    line-height: 1.5em;
    font-family: $title-text-font-2;
    font-size: 16px;
    text-align: right;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 0 !important;
  }
}

.liveStreamPluginContainer {
  @include liveStreamPluginContainer;

  width: 100%;
}
