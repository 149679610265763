@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin buttonBase {
  font-size: 14px;
  font-family: $title-text-font;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  padding: 5px 24px;
  border-radius: 4px;
}

@mixin alternateButton(
  $bgFill: $brand-white,
  $hoverBgFill: $primary-color,
  $color: $primary-color
) {
  @include buttonBase();

  background-color: $bgFill;
  border: 1px solid $color;
  color: $color;
  transition: border 0.3s, color 0.3s, background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);
  font-weight: normal;

  &.active,
  &:hover {
    background-color: $hoverBgFill;
    color: $bgFill;
  }

  &:hover {
    box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0.65);
  }

  &:focus {
    box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);
    border: 1px solid $color;
    background-color: $hoverBgFill;
  }

  &:disabled {
    color: rgba($hoverBgFill, 0.33);
  }
}

@mixin primaryButton($bgFill: $tertiary-color, $color: $brand-white) {
  @include buttonBase();

  background-color: $bgFill;
  border: 1px solid $bgFill;
  color: $color;
  transition: border 0.3s, color 0.3s, background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);
  border-radius: 5px;

  &:hover {
    background-color: $color;
    box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0.65);
    border: 1px solid $bgFill;
    color: $bgFill;
  }

  &:focus {
    box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);
    border: 1px solid $brand-black;
    background-color: $bgFill;
    color: $color;
  }

  &:disabled {
    color: rgba($bgFill, 0.33);
  }
}

@mixin secondaryButton($bgFill: $brand-gray-2, $color: $brand-black) {
  @include buttonBase();

  background-color: $bgFill;
  border: 1px solid $bgFill;
  color: $color;
  transition: border 0.3s, color 0.3s, background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);

  &.active,
  &:hover {
    border: 1px solid $bgFill;
    background-color: $brand-white;
  }

  &:hover {
    box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0.65);
  }

  &:disabled {
    color: rgba($bgFill, 0.33);
  }
}

@mixin mainPageSetting($top: 60px) {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: $maxBaseWidth;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
  margin: 0 auto;
  padding: 30px 20px;
  min-height: $bodyMinHeight;
  background-color: $brand-white;
  @include mq("phone-wide") {
    width: 91%;
  }
  @include mq("laptop") {
    padding: $top 40px;
  }
}

@mixin flexContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @include mq("midpoint") {
    flex-flow: row;
    margin-top: 0px;
    align-items: stretch;
    justify-content: space-between;
  }
}

@mixin gridFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin gridTitle {
  font-family: $title-text-font-2;
  font-size: 32px;
  margin: 0 0 15px;
  font-weight: 700;
  line-height: 1.16;
  text-align: center;
  color: $primary-color;
  text-transform: uppercase;

  @include mq("tablet-small") {
    font-size: 40px;
    margin: 0 0 30px;
  }
  @include mq("midpoint") {
    font-size: 50px;
  }
  @include mq("laptop") {
    font-size: 60px;
  }
}

@mixin smallCard {
  box-sizing: border-box;
  background: $brand-off-white;
  max-width: 280px;
  min-height: 280px;
  width: 100%;
  flex-flow: column;
  margin: 10px 1%;
  padding: 10px;
  justify-content: space-between;
  display: flex;
  align-content: center;

  @include mq("tablet") {
    max-width: 310px;
    min-height: 310px;
    padding: 30px;
  }

  h1 {
    font-family: $title-text-font;
    color: $primary-color;
    font-weight: 900;
    font-size: 18px;
    text-align: left;
    width: 100%;
  }

  .dateTime,
  .sessionMeta {
    font-family: $title-text-font;
    font-size: 15px;
    margin: 0;
    line-height: 20px;
  }

  .dateTime {
    color: $brand-gray;
    margin: 10px 0;
    font-weight: 500;
  }

  .sessionMeta {
    color: $primary-color;
    font-weight: 900;
  }
}

@mixin imgButton {
  border: none;
  background: transparent;
  padding: 5px;

  &:focus {
    background-color: $brand-white-3;
  }

  img {
    height: 20px;
    display: block;
  }
}

@mixin smallCardSubSession {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px 10px;
  min-height: 140px;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;

  @include mq("phablet") {
    padding: 30px 30px 15px;
  }

  @include mq("midpoint") {
    margin: 1% 0;
    width: 49%;
  }

  h2 {
    font-family: $title-text-font;
    padding: 0;
    margin: 0 0 30px 0;
    font-size: 14px;
    font-weight: 900;
    line-height: 1.19;
    text-align: left;
    color: $primary-color;
    width: 100%;

    @include mq("phablet") {
      font-size: 18px;
    }
  }
  h3 {
    font-family: $title-text-font;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.71;
    text-align: left;
    color: $secondary-color;

    @include mq("phablet") {
      font-size: 14px;
    }
  }
}

@mixin iframeWrapper {
  display: flex;
  width: 80%;
  min-width: 300px;
  min-height: 645px;
  align-items: center;
  margin: auto;

  &.matchmaking {
    max-width: 1000px;
  }
}

@mixin singleSpeakerContainer {
  display: flex;
  width: 100%;
  margin: auto;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @include mq("tablet") {
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  iframe {
    margin-top: 50px;
    height: 450px;
  }
}

@mixin socialIcons(
  $bgFill: $brand-black,
  $size: 35px,
  $hover: $brand-light-grey
) {
  background: $bgFill;
  border-radius: 50%;
  width: $size;
  height: $size;
  transition: background 0.3s ease-in-out;

  img {
    width: 100%;
    display: block;
  }

  &:hover,
  &:focus {
    background: $hover;
  }
}

@mixin inputSettings {
  border: solid 1px rgba($brand-gray, 0.1);
  outline: none;
  font-size: 16px;
  font-family: $title-text-font;
  color: $primary-color;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  &::placeholder {
    color: $primary-color;
    font-size: 16px;
    font-family: $title-text-font;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $primary-color;
    font-size: 16px;
    font-family: $title-text-font;
  }
}

@mixin clickCardSetting {
  border: 1px solid $brand-black;
  border-radius: 5px;
  background: rgba($brand-black, 0.02);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0);
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.33);
    background-color: $brand-white-2;
  }

  &:focus {
    outline-color: $brand-black;
  }
}
