@mixin headerLabel() {
  display: flex;
  align-items: center;
  height: 57px;
  position: relative;
}

@mixin dropDownTitle {
  margin-left: 10px;
  margin-right: 44px;
  font-weight: bold;
  font-family: $title-text-font;
}

@mixin dropDownCaret {
  &:after {
    content: "";
    background: url("/images/icons/chevron-down-black.svg") top center no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 18px;
  }

  @include mq("desktop") {
    &:after {
      top: 22px;
    }
  }
}

@mixin activeDropdown {
  display: block;
  background: $brand-white;
  min-width: 130px;
  z-index: 10;
  font-size: 14px;
  font-family: $title-text-font;
  background-color: $brand-white;
}
