@import "../../../css/core";
// Mobile first implications

.preEvent {
  &.headerLogo {
    img {
      @include mq("tablet") {
        height: 50px;
      }
    }
  }
}

.headerLogo {
  img {
    height: 35px;
    cursor: pointer;

    @include mq("desktop-wide") {
      height: 50px;
    }
  }
}

.searchIcon {
  position: relative;
  margin-top: 15px;

  @include mq("desktop") {
    margin-top: 0;
  }

  &:focus {
    border: 1px solid $brand-black;
    outline: none;
  }

  &:hover {
    svg {
      path {
        fill: $brand-white;
        stroke: $primary-color;
      }
    }
  }

  svg {
    path {
      fill: $primary-color;
    }
  }
}
