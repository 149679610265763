@import "../../../css/core";

.scheduleMeetingContainer {
  margin-top: 20px;
  display: flex;
  max-width: 100%;
  justify-content: flex-start;
  @include mq("midpoint")
  {
    justify-content: flex-end;
  }
}

.detailsButton {
  @include primaryButton;

  font-weight: 700;

  &.inactive {
    background-color: $brand-light-grey;
    border: unset;
    &:hover:not(:disabled) {
      background-color: $brand-light-grey;
      color: $brand-white;
      border: unset;
      cursor: default;
    }
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
}
